<template>
  <b-modal
    v-model="showing"
    size="lg"
    :title="$t('common.title.edit_message')"
    @close="closeModal"
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="message-center-content">
      <div class="add-message-container">
        <b-form-group :label="$t('common.label.title')">
          <b-form-input v-model="title"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('common.label.content')">
          <b-form-textarea v-model="text" rows="3"></b-form-textarea>
        </b-form-group>
      </div>
    </div>
    <template #modal-footer>
      <b-button size="sm" class="float-right" @click="closeModal">
        {{ $t('common.action.cancel') }}
      </b-button>
      <b-button size="sm" class="float-right confirm-btn" @click="editMessage">
        {{ $t('common.action.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'EditMessageModal',
  props: {
    message: {
      type: Object,
      required: true,
    },
    showEditMessageModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showing: false,
      text: '',
      title: '',
    }
  },
  computed: {
    ...mapGetters(['messages', 'current_user']),
  },
  methods: {
    ...mapActions(['edit_message', 'editUser', 'update_unread']),
    closeModal() {
      this.showing = false
      this.$emit('close-modal')
    },
    async mark_all_read() {
      const latest_msg = this.messages.sort((a, b) => {
        return moment(a.DOC).isAfter(b.DOC) ? -1 : 1
      })[0]

      const res = await this.editUser({
        msgs_time: latest_msg.SK,
        email: this.current_user.email,
      })

      if (res.success) {
        this.update_unread({ amount: 0 })
      }
    },
    cancelMessage() {
      this.addMessageVisibile = false
    },
    async editMessage() {
      const old_time_stamp = this.message.DOC
      const res = await this.edit_message({
        content: this.text,
        title: this.title,
        public: false,
        DOC: this.message.DOC,
      })

      if (res.success) {
        this.$root.$emit('show_toast', {
          message: res.message,
          type: 1,
        })
        this.mark_all_read()
        this.$emit('sort-list', {
          old_time_stamp,
          new_time_stamp: res.payload.SK,
        })
        this.closeModal()
      } else {
        this.$root.$emit('show_toast', {
          message: res.message,
          type: 2,
        })
      }
    },
  },
  mounted() {
    this.showing = this.showEditMessageModal
    if (this.message) {
      this.text = this.message.content
      this.title = this.message.title
    }
  },
}
</script>

<style lang="scss" scoped></style>
