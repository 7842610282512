const common = {
  title: {
    log_in: 'Log in',
    login: 'Login',
    co2_emissions: 'C02 EMISSIONS',
    sum_consumption: 'SUM CONSUMPTION',
    sum_procurement: 'SUM PROCUREMENT',
    meters: 'Meters',
    user_management: 'User management',
    dashboard: 'Dashboard',
    analytics: 'Analytics',
    data: 'Data',
    admin_management: 'Admin management',
    add_new_user: 'Add new user',
    edit_user: 'Edit user',
    add_new_meter: 'Add new meter',
    edit_meter: 'Edit meter',
    add_new_tag: 'Add new tag',
    edit_tag: 'Edit tag',
    add_new_group: 'Add new group',
    edit_group: 'Edit group',
    groups: 'Groups',
    tags: 'Tags',
    energy_source_per_sector: 'Energy source per sector',
    live_electricity: 'Live electricity',
    GHG_emissions: 'GHG emissions',
    transport: 'Transport',
    energy_consumption: 'Energy consumption',
    message_center: 'Message center',
    edit_message: 'Edit message',
    tutorial_archive: 'Tutorial archive',
    info: 'Info',
    misc: 'Misc',
  },
  label: {
    email: 'Email',
    password: 'Password',
    dataset: 'Dataset',
    custom: 'Custom',
    raw_unit: 'Input unit',
    meter_id: 'Id',
    wh_factor: 'MWh factor',
    co2_factor: 'C02 factor',
    total: 'Total',
    annual_value: 'Annual value',
    id: 'ID',
    name: 'Name',
    type: 'Type',
    total_energy: 'Total energy',
    total_emission: 'Total emission',
    group: 'Group',
    tag: 'Tag',
    all: 'All',
    automatic: 'Automatic',
    virtual: 'Virtual',
    manual: 'Manual',
    data: 'Data',
    user: 'User',
    phone_number: 'Phone number',
    profile: 'Profile',
    role: 'Role',
    admin: 'Admin',
    regular: 'Regular',
    meter: 'Meter',
    no_data: 'no data',
    history: 'HISTORY',
    change_password: 'Change password',
    current_password: 'Current password',
    new_password: 'New password',
    confirm_new_password: 'Confirm new password',
    content: 'Content',
    title: 'Title',
    confirm_password: 'Confirm password',
    page_not_found: 'Page not found',
    here: 'here',
    since: 'since',
    consumption: 'Consumption',
    production: 'Production',
    grand_total: 'Grand total',
    factor: 'factor',
    //resource group
    electricity: 'Electricity',
    heating: 'Heating',
    fule: 'Fuel',
    bio_fuel: 'Bio fuel',
    fossil_fuel: 'Fossil fuel',

    //Unit
    unit: 'Unit',
    MWh: 'MWh',
    C02: 't CO₂-eq',
    wh: 'Wh',
    tco2: 't CO₂-eq',

    //Date, time type
    a_day_in_hours: '24 hours',
    week: 'Week',
    interval: 'Interval',
    year: 'Year',
    day: 'Day',
    month: 'Month',
    period: 'Period',
    frequency: 'Frequency',
    fifteen_minutes: '15 minutes',
    hour: 'Hour',
    date: 'Date',
    monthly: 'Monthly',
  },
  placeholder: {
    add_more_dataset: 'Add more dataset (for a different year in the same interval)',
    generate_graph: 'Generate graph',
    export_to_excel: 'Export to excel',
    this_year: 'This year',
    last_month: 'Last month',
    annual_input: 'Annual input',
    per_page: 'Per page',
    edit_annual_value_of: 'Edit annual value of',
    evenly_split: 'Evenly split',
    populate_empty_slots: 'Populate empty slots',
    there_are_no_empty_slots_to_fill: 'There are no empty slots to fill.',
    there_are_unsaved_values:
      'There are unsaved values. Either save or discard them to continue!',
    overwrite_existing_monthly_value_warning:
      'This action will overwrite the existing monthly value. Please confirm to proceed!',
    add_new_user: 'Add new user',
    add_new_meter: 'Add new meter',
    add_new_group: 'Add new group',
    add_new_tag: 'Add new tag',
    press_enter_to_select: 'Press enter to select',
    press_enter_to_remove: 'Press enter to remove',
    selected: 'Selected',
    no_results_found: 'No results found',
    no_emissions: 'No emissions',
    new_message: 'New message...',
    add_new_message: 'Add new message',
    confirm_deleting_message: 'Confirm deleting message!',
    go_back_to: 'Go back to ',
    you_are_not_signed_in: 'You are not logged in',
    confirm_deleting_tag: 'Confirm deleting tag!',
    confirm_deleting_user: 'Confirm deleting user',
    too_many_data_points: 'Can not render graph because the number of data points cross the limit!'
  },
  action: {
    log_in: 'Log in',
    log_out: 'Log out',
    cancel: 'Cancel',
    save: 'Save',
    close: 'Close',
    ok: 'Ok',
    discard: 'Discard',
    confirm: 'Confirm',
    add: 'Add',
    refresh: 'Refresh',
    edit: 'Edit',
  },
}

export default common
