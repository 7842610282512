<template>
  <div class="navbar">
    <div class="navbar-left">
      <!-- LOGO PLACEHOLDER -->
      <div class="logo-container"><img :src="flexens_logo" /></div>
      <div class="link-container" v-if="current_user">
        <div
          class="nav-link"
          :class="{ active: current_view == 'Home' }"
          @click="goToView('Home')"
        >
          {{ $t('common.title.dashboard').toUpperCase() }}
        </div>
        <div
          class="nav-link"
          :class="{ active: current_view == 'AnalyticsView' }"
          @click="goToView('AnalyticsView')"
        >
          {{ $t('common.title.analytics').toUpperCase() }}
        </div>
        <div
          class="nav-link"
          :class="{ active: current_view == 'DataView' }"
          @click="goToView('DataView')"
        >
          {{ $t('common.title.data').toUpperCase() }}
        </div>
        <div
          class="nav-link"
          :class="{ active: current_view == 'AdminView' }"
          @click="goToView('AdminView')"
          v-if="is_admin"
        >
          {{ $t('common.title.admin_management').toUpperCase() }}
        </div>
      </div>
    </div>
    <div class="navbar-right" v-if="current_user">
      <div class="message-container" @click="toggleArchiveModal">
        <b-icon icon="archive"></b-icon>
      </div>
      <div class="message-container" @click="toggleMessageModal">
        <b-icon icon="envelope"></b-icon>
        <b-badge variant="light" class="badge">{{ no_unread }}</b-badge>
      </div>
      <div class="user-name">
        {{ current_user.name ? current_user.name : 'John Doe' }}
      </div>
      <b-dropdown size="lg" no-caret class="user-btn">
        <template #button-content>
          <b-icon icon="person-circle"></b-icon>
        </template>
        <b-dropdown-item @click="toggleChangePwdModal(true)">{{
          $t('common.label.change_password')
        }}</b-dropdown-item>
        <b-dropdown-item @click="handleLogOut">{{
          $t('common.action.log_out')
        }}</b-dropdown-item>
      </b-dropdown>
    </div>

    <!-- Message modal -->
    <message-modal
      :showMessageModal="showMessageModal"
      @close-modal="toggleMessageModal"
      v-if="showMessageModal"
    />

    <!-- Archive modal -->
    <archive-modal
      :showArchiveModal="showArchiveModal"
      @close-modal="toggleArchiveModal"
      v-if="showArchiveModal"
    />

    <!-- Change password modal -->
    <b-modal
      v-model="showChangePwdModal"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      :title="$t('common.label.change_password')"
    >
      <b-form-group
        :label="$t('common.label.current_password') + '*'"
        :state="
          $v.current_password.$dirty
            ? !$v.current_password.$error
              ? true
              : false
            : null
        "
        :invalid-feedback="
          $t('apiRelated.placeholder.required', {
            key: $t('common.label.current_password'),
          })
        "
      >
        <b-form-input
          v-model="current_password"
          type="password"
          :state="
            $v.current_password.$dirty
              ? !$v.current_password.$error
                ? true
                : false
              : null
          "
          @change="$v.current_password.$touch()"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('common.label.new_password') + '*'"
        :state="
          $v.new_password.$dirty
            ? !$v.new_password.$error
              ? true
              : false
            : null
        "
        :invalid-feedback="
          $t('apiRelated.placeholder.required', {
            key: $t('common.label.new_password'),
          })
        "
      >
        <b-form-input
          v-model="new_password"
          type="password"
          :state="
            $v.new_password.$dirty
              ? !$v.new_password.$error
                ? true
                : false
              : null
          "
          @change="$v.new_password.$touch()"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('common.label.confirm_new_password') + '*'"
        :state="
          $v.confirm_new_password.$dirty
            ? !$v.confirm_new_password.$error
              ? true
              : false
            : null
        "
        :invalid-feedback="
          !$v.confirm_new_password.required
            ? $t('apiRelated.placeholder.required', {
                key: $t('common.label.confirm_new_password'),
              })
            : $t('apiRelated.message.does_not_match_new_password')
        "
      >
        <b-form-input
          v-model="confirm_new_password"
          type="password"
          :state="
            $v.confirm_new_password.$dirty
              ? !$v.confirm_new_password.$error
                ? true
                : false
              : null
          "
          @change="$v.confirm_new_password.$touch()"
        ></b-form-input>
      </b-form-group>
      <template #modal-footer>
        <b-button
          size="sm"
          class="float-right"
          @click="toggleChangePwdModal(false)"
        >
          {{ $t('common.action.close') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="confirmChangePwd"
        >
          {{ $t('common.action.ok') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import flexens_logo from '@/assets/flexens_logo.svg'
import { required } from 'vuelidate/lib/validators'
import MessageModal from '@/components/modals/MessageModal.vue'
import ArchiveModal from '@/components/modals/ArchiveModal.vue'

export default {
  name: 'NavBar',
  components: {
    MessageModal,
    ArchiveModal,
  },
  data() {
    return {
      flexens_logo,
      showChangePwdModal: false,
      confirm_new_password: null,
      new_password: null,
      current_password: null,
      showMessageModal: false,
      showArchiveModal: false,
    }
  },
  validations() {
    return {
      current_password: {
        required,
      },
      new_password: {
        required,
      },
      confirm_new_password: {
        required,
        matched: (value) => {
          if (value) {
            return value === this.new_password
          } else {
            return true
          }
        },
      },
    }
  },
  computed: {
    ...mapGetters(['current_view', 'current_user', 'no_unread', 'is_admin']),
    route() {
      return this.$route
    },
  },
  methods: {
    ...mapActions([
      'setCurrentView',
      'logOut',
      'changePassword',
      'fetch_unread',
    ]),
    goToView(view) {
      this.$router.push({ name: view }).catch(() => {})
      this.setCurrentView(view)
    },
    handleLogOut() {
      this.logOut()
    },
    toggleChangePwdModal() {
      this.$v.$reset()
      this.confirm_new_password = null
      this.new_password = null
      this.current_password = null

      this.showChangePwdModal = !this.showChangePwdModal
    },
    toggleMessageModal() {
      this.showMessageModal = !this.showMessageModal
    },
    toggleArchiveModal() {
      this.showArchiveModal = !this.showArchiveModal
    },
    async confirmChangePwd() {
      this.$v.$touch()
      if (this.$v.$dirty && !this.$v.$error) {
        const payload = {
          old_password: this.current_password,
          new_password: this.new_password,
        }

        const res = await this.changePassword(payload)

        if (res.success) {
          this.$root.$emit('show_toast', {
            message: res.message,
            type: 1,
          })
          this.toggleChangePwdModal(false)
        } else {
          this.$root.$emit('show_toast', {
            message: res.message,
            type: 2,
          })
        }
      }
    },
    async get_unread() {
      await this.fetch_unread()
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setCurrentView(this.route.name)
      this.get_unread()
    })
  },
  watch: {
    route() {
      this.setCurrentView(this.route.name)
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.25rem 1rem;
  display: flex;
  justify-content: space-between;
  background-color: $main-bg-dark;
  width: 100vw;
  border-bottom: 1px solid rgba(204, 204, 220, 0.07);
  height: 3.5rem;
  max-height: 3.5rem;
  min-height: 3.5rem;
  z-index: 10;
  color: $main-text-color;
  align-items: center;
  // font-family: Open Sans;
  @media (max-width: 1500px) {
    height: 2.5rem;
    max-height: 2.5rem;
    min-height: 2.5rem;
  }
}
.logo-container {
  width: 8rem;
  height: 3rem;
  position: relative;
  bottom: 0rem;

  & img {
    height: 3rem;
    min-height: 2.5rem;
    transform: scale(1.5);
  }
  @media (max-width: 1500px) {
    width: 6rem;
    height: 2.5rem;
    & img {
      height: 2.5rem;
       transform: scale(1.2);
    }
  }
}
.navbar-left {
  display: flex;
  align-items: center;
  @media (max-width: 1500px) {
    height: 3vh;
  }
}
.link-container {
  display: flex;
  align-items: center;
  font-size: 1rem;
  @media (max-width: 1500px) {
    font-size: 0.8rem;
  }
}
.navbar-right {
  display: flex;
  align-items: center;
  @media (max-width: 1500px) {
    height: 3vh;
  }
}
.user-btn {
  margin-left: 1rem;

  & ::v-deep button {
    background-color: transparent;
    border: none;
    box-shadow: none !important;
  }

  @media (max-width: 1500px) {
    & ::v-deep button {
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
    & ::v-deep svg {
      font-size: 0.8rem !important;
    }
  }
}
.user-name {
  margin-top: 0.1rem;
  // font-family: Open Sans;
  @media (max-width: 1500px) {
    font-size: 0.8rem;
  }
}
.nav-link {
  cursor: pointer;
  margin-right: 1rem;
  // font-family: Roboto Condensed;
  font-weight: 600;
  &.active {
    color: white;
  }
  &:hover {
    color: white;
  }
  @media (max-width: 1500px) {
    padding: 0rem 0.5rem;
  }
}
.message-container {
  margin-right: 2rem;
  position: relative;
  cursor: pointer;
  & svg {
    font-size: 1.5rem;
  }
  @media (max-width: 1500px) {
    & svg {
      font-size: 0.8rem;
    }
  }
}
.badge {
  position: absolute;
  top: -0.2rem;
  @media (max-width: 1500px) {
    font-size: 0.65rem;
    padding: 0.15rem 0.25rem;
    line-height: 0.85;
  }
}
</style>
