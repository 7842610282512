const apiRelated = {
  message: {
    save_successfully: 'Saved successfully!',
    success: 'Success',
    error: 'Error',
    warning: 'Warning',
    invalid_email: 'Email is invalid',
    missing_org: 'Missing organization',
    user_missing_email: 'User has no email',
    connection_error:
      'Connection error, please refresh or go back to login page.',
    access_token_expired: 'Your session is expired, please login again.',
    does_not_match_new_password: "Doesn't match new password",
    does_not_match_password: "Doesn't match password",
    not_enough_authorization_level: "Don't have enough authorization level!",
  },
  placeholder: {
    action_success: '{action} {key} successfully!',
    action_fail: 'Could not {action} {key}!',
    required: '{key} is required',
  },
  item: {
    user: 'user',
    users: 'users',
    credential: 'credential',
    meter: 'meter',
    meters: 'meters',
    admin_data: 'admin data',
    tag: 'tag',
    group: 'group',
    data: 'data',
    password: 'password',
    messages: 'messages',
    message: 'message',
    unread_messages: 'unread messages',
    archives: 'archives',
    info: 'info',
    graph: 'graph',
  },
  action: {
    log_in: 'Log in',
    logged_in: 'Logged in',
    log_out: 'Log out',
    logged_out: 'Logged out',
    add: 'Add',
    added: 'Added',
    fetch: 'Fetch',
    fetched: 'Fetched',
    edit: 'Edit',
    edited: 'Edited',
    updated: 'Updated',
    update: 'Update',
    change: 'Change',
    changed: 'Change',
    deleted: 'Deleted',
    delete: 'Delete',
    confirm: 'Confirm',
    show: 'Show',
    showed: 'Showed',
  },
}

export default apiRelated
