<template>
  <b-modal
    v-model="showing"
    size="xl"
    :title="$t('common.title.tutorial_archive')"
    @close="closeModal"
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="archive-content">
      <b-table
        :items="items"
        :fields="fields"
        :current-page="current_page"
        :per-page="per_page"
        :sort-by.sync="sorter"
        :sort-desc.sync="sort_desc"
        stacked="md"
        show-empty
        striped
        hover
        class="archive-table"
        @row-clicked="handleToggleDetails"
      >
        <template #empty="scope">
          <p class="empty-text">{{ scope.emptyText }}</p>
        </template>

        <template #row-details="row">
          <b-card>
            <div class="description-text">{{ row.item.description }}</div>
            <b-embed
              type="iframe"
              aspect="16by9"
              :src="row.item.src"
              allowfullscreen
            ></b-embed>
          </b-card>
        </template>
      </b-table>
    </div>
    <template #modal-footer>
      <b-button size="sm" class="float-right" @click="closeModal">
        {{ $t('common.action.cancel') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ArchiveModal',
  props: {
    showArchiveModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showing: false,
      items: [],
      sorter: 'title',
      current_page: 1,
      total_rows: 0,
      sort_desc: false,
      per_page: 25,
      pageOptions: [10, 25, 50, 100],
      expandedRows: [],
    }
  },
  computed: {
    ...mapGetters(['archives']),
    fields() {
      return [
        {
          key: 'title',
          label: this.$t('common.label.title'),
          sortable: true,
          thClass: 'title-cell',
          tdClass: 'title-cell',
        },
      ]
    },
  },
  methods: {
    ...mapActions(['fetch_archive']),
    closeModal() {
      this.showing = false
      this.$emit('close-modal')
    },
      handleToggleDetails(row) {
      this.items
        .filter((i) => {
          if (i.archive_id !== row.archive_id) {
            return true
          } else {
            i._showDetails = !i._showDetails
            if (i._showDetails) {
              this.selected_row = i
            }
          }
        })
        .forEach((i) => (i._showDetails = false))

      const index = this.expandedRows.findIndex((r) => r === row.archive_id)
      if (index > -1) {
        this.expandedRows.splice(index, 1)
      } else {
        this.expandedRows = [row.archive_id]
      }
    },
    filter_archives() {
      this.items = this.archives
        .map((m) => {
          return {
            ...m,
            _showDetails: this.expandedRows.includes(m.archive_id)
              ? true
              : false,
          }
        })
        .sort((a, b) => {
          if (!this.sort_desc) {
            return a.title.localeCompare(b.title)
          } else {
            return -a.title.localeCompare(b.title)
          }
        })
    },
    async get_archive() {
      const res = await this.fetch_archive()

      if (!res.success) {
        this.$root.$emit('show_toast', {
          message: res.message,
          type: 2,
        })
      } else {
        this.filter_archives()
      }
    },
  },
  mounted() {
    this.showing = this.showArchiveModal
    this.get_archive()
  },
}
</script>

<style lang="scss" scoped>
.description-text {
  margin-bottom: 1rem;
}
</style>
