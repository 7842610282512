<template>
  <div id="app">
    <nav-bar
      v-if="
        current_user &&
        !is_loading &&
        !connection_error &&
        !($router.name === 'LoginView')
      "
    />
    <router-view
      :key="$route.path"
      v-if="!is_loading && (!connection_error || $router.name === 'LoginView')"
    />
    <div>
      <b-modal
        v-model="connection_error"
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
      >
        {{
          access_token_expired
            ? $t('apiRelated.message.access_token_expired')
            : $t('apiRelated.message.connection_error')
        }}
        <template #modal-footer>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="refreshPage"
            v-if="!access_token_expired"
          >
            {{ $t('common.action.refresh') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="goToLogin"
          >
            {{ $t('common.action.log_in') }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/layouts/NavBar.vue'
import showToast from '@/utils/customToast.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      connection_error: false,
      is_loading: false,
    }
  },
  computed: {
    ...mapGetters(['current_user', 'access_token_expired']),
  },
  methods: {
    ...mapActions(['fetch_login_data', 'logOut']),
    async getLoginData() {
      this.is_loading = true
      this.connection_error = false
      const res = await this.fetch_login_data()
      this.is_loading = false
      if (!res.success) {
        this.connection_error = true
      } else {
        this.connection_error = false
      }
    },
    goToLogin() {
      this.connection_error = false
      this.logOut()
    },
    refreshPage() {
      window.location.reload()
    },
  },
  mounted() {
    this.getLoginData()

    this.$root.$on('show_toast', ({ message, type }) => {
      if (type === 1) {
        showToast(
          this.$t('apiRelated.message.success'),
          message,
          'success',
          this
        )
      } else {
        showToast(this.$t('apiRelated.message.error'), message, 'error', this)
      }
    })
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
#app {
  font-family: Open Sans, Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.confirm-btn {
  background-color: #10384b !important;
  border-color: #10384b !important;
  color: $main-text-color !important;
  &:hover {
    color: #d6d6e3 !important;
    background-color: #3f515e !important;
  }
}
.multiselect {
  &.error {
    & .multiselect__tags {
      border-color: red !important;
    }
  }
}
.multiselect__tags {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
}
// .multiselect__option--highlight {
//     background: #74b4f9 !important;
//     &::after {
//       background: #74b4f9 !important;
//     }
// }
// .multiselect__tag {
//    background: #74b4f9 !important;
// }
.page-item.active .page-link {
  background-color: #10384b !important;
  border-color: #10384b !important;
}
</style>
