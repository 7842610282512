const LocalStorageService = (function () {
  let _service
  function _getService() {
    if (!_service) {
      _service = this
      return _service
    }
    return _service
  }
  function _setAccessToken(access_token) {
    localStorage.setItem('flexens_access_token', access_token)
  }
  function _getAccessToken() {
    return localStorage.getItem('flexens_access_token')
  }
  function _getCsrfToken() {
    return localStorage.getItem('flexens_csrf_token')
  }
  function _setCsrfToken(access_token) {
    localStorage.setItem('flexens_csrf_token', access_token)
  }
  function _getRefreshToken() {
    return localStorage.getItem('refresh_token')
  }
  function _clearAccessToken() {
    localStorage.removeItem('flexens_access_token')
  }
  function _clearCsrfToken() {
    localStorage.removeItem('flexens_csrf_token')
  }

  return {
    getService: _getService,
    setAccessToken: _setAccessToken,
    getAccessToken: _getAccessToken,
    setCsrfToken: _setCsrfToken,
    getCsrfToken: _getCsrfToken,
    getRefreshToken: _getRefreshToken,
    clearAccessToken: _clearAccessToken,
    clearCsrfToken: _clearCsrfToken,
  }
})()

export default LocalStorageService
