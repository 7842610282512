<template>
  <b-modal
    v-model="showing"
    size="xl"
    :title="$t('common.title.message_center')"
    @close="closeModal"
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="message-center-content">
      <div
        v-if="is_admin"
        @click="addMessageVisibile = !addMessageVisibile"
        class="add-message-text"
      >
        {{ $t('common.placeholder.add_new_message') }}
      </div>
      <b-collapse v-model="addMessageVisibile" v-if="is_admin">
        <div class="add-message-container">
          <b-form-group :label="$t('common.label.title')">
            <b-form-input v-model="title"></b-form-input>
          </b-form-group>
          <b-form-group :label="$t('common.label.content')">
            <b-form-textarea v-model="text" rows="3"></b-form-textarea>
          </b-form-group>
          <div class="add-message-btns">
            <b-button size="sm" @click="cancelMessage">{{
              $t('common.action.cancel')
            }}</b-button>
            <b-button size="sm" variant="primary" @click="sendMessage">{{
              $t('common.action.add')
            }}</b-button>
          </div>
        </div>
      </b-collapse>
      <b-table
        :items="items"
        :fields="fields"
        :current-page="current_page"
        :per-page="per_page"
        :sort-by.sync="sorter"
        :sort-desc.sync="sort_desc"
        stacked="md"
        show-empty
        striped
        hover
        class="message-table"
        @row-clicked="handleToggleDetails"
      >
        <template #empty="scope">
          <p class="empty-text">{{ scope.emptyText }}</p>
        </template>

        <template #cell(DOC)="row">
          {{ getDate(row.item.DOC) }}
        </template>

        <template #row-details="row">
          <b-card>
            {{ row.item.content }}
          </b-card>
        </template>

        <template #cell(action)="row">
          <div class="action-container" v-if="is_admin">
            <b-icon
              icon="pencil"
              class="icon-btn"
              @click.stop="toggleEditMessageModal(row.item)"
            ></b-icon>
            <b-icon
              icon="trash"
              class="icon-btn"
              @click.stop="toggleConfirmDeleteMessageModal(row.item)"
            ></b-icon>
          </div>
        </template>
      </b-table>
    </div>
    <template #modal-footer>
      <b-button size="sm" class="float-right" @click="closeModal">
        {{ $t('common.action.close') }}
      </b-button>
    </template>

    <!-- edit message -->
    <edit-message-modal
      :showEditMessageModal="showEditMessageModal"
      v-if="showEditMessageModal"
      :message="selected_message"
      @close-modal="toggleEditMessageModal"
      @sort-list="filter_messages"
    />

    <!-- Confirm delete message -->
    <b-modal
      v-model="showConfirmDeleteMessageModal"
      :title="$t('common.title.edit_message')"
      @close="toggleConfirmDeleteMessageModal"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="message-center-content">
        {{ $t('common.placeholder.confirm_deleting_message') }}
      </div>
      <template #modal-footer>
        <b-button
          size="sm"
          class="float-right"
          @click="toggleConfirmDeleteMessageModal"
        >
          {{ $t('common.action.cancel') }}
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          class="float-right"
          @click="deleteMessage"
        >
          {{ $t('common.action.confirm') }}
        </b-button>
      </template>
    </b-modal>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import EditMessageModal from '@/components/modals/EditMessageModal.vue'
import { convertDateToLocal } from '@/utils/helper_funcs.js'

export default {
  name: 'MessageModal',
  components: {
    EditMessageModal,
  },
  props: {
    showMessageModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showing: false,
      items: [],
      sorter: 'DOC',
      current_page: 1,
      total_rows: 0,
      sort_desc: true,
      per_page: 25,
      pageOptions: [10, 25, 50, 100],
      text: '',
      title: '',
      addMessageVisibile: false,
      selected_message: null,
      showEditMessageModal: false,
      showConfirmDeleteMessageModal: false,
      expandedRows: [],
    }
  },
  computed: {
    ...mapGetters(['is_admin', 'messages', 'current_user']),
    fields() {
      if (this.is_admin) {
        return [
          {
            key: 'DOC',
            label: this.$t('common.label.date'),
            sortable: true,
            thClass: 'date-cell',
            tdClass: 'date-cell',
          },
          {
            key: 'title',
            label: this.$t('common.label.title'),
            sortable: false,
            thClass: 'title-cell',
            tdClass: 'title-cell',
          },
          {
            key: 'action',
            label: '',
            thClass: 'action-cell',
            tdClass: 'action-cell',
          },
        ]
      }
      return [
        {
          key: 'DOC',
          label: this.$t('common.label.date'),
          sortable: true,
          thClass: 'date-cell',
          tdClass: 'date-cell',
        },
        {
          key: 'title',
          label: this.$t('common.label.title'),
          sortable: false,
          thClass: 'title-cell',
          tdClass: 'title-cell',
        },
      ]
    },
    getDate() {
      return (date) => {
        return convertDateToLocal(
          date,
          'YYYY-MM-DD HH:mm:ss',
          'DD-MM-YYYY HH:mm:ss'
        )
      }
    },
  },
  methods: {
    ...mapActions([
      'fetch_messages',
      'send_message',
      'delete_message',
      'editUser',
      'update_unread',
    ]),
    toggleEditMessageModal(entry) {
      this.selected_message = entry ?? null
      this.showEditMessageModal = !this.showEditMessageModal
    },
    toggleConfirmDeleteMessageModal(entry) {
      this.selected_message = entry ?? null
      this.showConfirmDeleteMessageModal = !this.showConfirmDeleteMessageModal
    },
    closeModal() {
      this.showing = false
      this.$emit('close-modal')
    },
    handleToggleDetails(row) {
      this.items
        .filter((i) => {
          if (i.SK !== row.SK) {
            return true
          } else {
            i._showDetails = !i._showDetails
            if (i._showDetails) {
              this.selected_row = i
            }
          }
        })
        .forEach((i) => (i._showDetails = false))

      const index = this.expandedRows.findIndex((r) => r === row.SK)
      if (index > -1) {
        this.expandedRows.splice(index, 1)
      } else {
        this.expandedRows = [row.SK]
      }
    },
    filter_messages(data) {
      if (data) {
        const index = this.expandedRows.findIndex(
          (i) => i === data.old_time_stamp
        )
        if (index > -1) {
          this.expandedRows.splice(index, 1, data.new_time_stamp)
        }
      }
      this.items = this.messages
        .map((m) => {
          return {
            ...m,
            _showDetails: this.expandedRows.includes(m.SK) ? true : false,
          }
        })
        .sort((a, b) => {
          if (this.sort_desc) {
            return moment(a.DOC).isAfter(b.DOC) ? -1 : 1
          } else {
            return moment(a.DOC).isAfter(b.DOC) ? 1 : -1
          }
        })
    },
    async get_messages() {
      const res = await this.fetch_messages()

      if (res.success) {
        this.filter_messages()
        await this.mark_all_read()
      } else {
        this.$root.$emit('show_toast', {
          message: res.message,
          type: 2,
        })
      }
    },
    async mark_all_read() {
      const latest_msg = this.messages.sort((a, b) => {
        return moment(a.DOC).isAfter(b.DOC) ? -1 : 1
      })[0]

      const res = await this.editUser({
        msgs_time: latest_msg.SK,
        email: this.current_user.email,
      })

      if (res.success) {
        this.update_unread({ amount: 0 })
      }
    },
    cancelMessage() {
      this.addMessageVisibile = false
    },
    async sendMessage() {
      const res = await this.send_message({
        content: this.text,
        title: this.title,
        public: false,
      })

      if (res.success) {
        this.filter_messages()
        this.text = ''
        this.title = ''
        this.mark_all_read()
      } else {
        this.$root.$emit('show_toast', {
          message: res.message,
          type: 2,
        })
      }
    },
    async deleteMessage() {
      const res = await this.delete_message({
        public: this.selected_message.public,
        DOC: this.selected_message.DOC,
        SK: this.selected_message.SK,
      })

      if (res.success) {
        this.filter_messages()
        this.$root.$emit('show_toast', {
          message: res.message,
          type: 1,
        })
        this.toggleConfirmDeleteMessageModal()
      } else {
        this.$root.$emit('show_toast', {
          message: res.message,
          type: 2,
        })
      }
    },
  },
  mounted() {
    this.get_messages()
    this.showing = this.showMessageModal
  },
}
</script>

<style lang="scss" scoped>
::v-deep .modal-dialog {
  max-width: 70vw !important;
}
.add-message {
  &-text {
    margin-bottom: 1rem;
    cursor: pointer;
    color: #007bff;
    &:hover {
      text-decoration: underline;
    }
  }
  &-container {
    margin-bottom: 1rem;
  }
  &-btns {
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
    justify-content: flex-end;
    & button {
      margin-left: 0.5rem;
    }
  }
}
.action-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & svg {
    // color: black;
  }
}
.icon-btn {
  cursor: pointer;
  margin: 0 0.5rem;
  height: 1.1rem;
  width: 1.1rem;
}
::v-deep .date-cell {
  width: 15%;
}
::v-deep .title-cell {
  width: 75%;
}
::v-deep .action-cell {
  width: 10%;
}
</style>
