import router from '@/router/index'
import LocalStorageService from '@/utils/LocalStorage.js'
import Vue from 'vue'
const localStorageService = LocalStorageService.getService()

export default {
  //UI
  SET_CURRENT_VIEW(state, view) {
    state.current_view = view
  },
  ACCESS_TOKEN_EXPIRED(state, expired) {
    state.access_token_expired = expired
  },
  //DASHBOARD
  FETCH_DASHBOARD_DATA(
    state,
    {
      sum_consumption,
      sum_procurement,
      CO2_emissions,
      live_line_emission,
      live_pie_emission,
      live_emissions,
    }
  ) {
    if (sum_consumption) {
      state.dashboard.sum_consumption = sum_consumption
    }
    if (sum_procurement) {
      state.dashboard.sum_procurement = sum_procurement
    }
    if (CO2_emissions) {
      state.dashboard.CO2_emissions = CO2_emissions
    }
    if (live_line_emission) {
      state.dashboard.live_line_emission = live_line_emission
    }
    if (live_pie_emission) {
      state.dashboard.live_pie_emission = live_pie_emission
    }
    if (live_emissions) {
      state.dashboard.live_emissions = live_emissions
    }
  },
  FETCH_LIVE_EMISSIONS_DATA(state, data) {
    state.dashboard.live_emissions = data
  },

  //DATA VIEW
  FETCH_GROUPS(state, data) {
    state.groups = data
  },
  FETCH_TAGS(state, data) {
    state.tags = data
  },
  FETCH_RESOURCES(state, data) {
    state.resources = data
  },
  FETCH_SINGLE_DATA_RESOURCE(state, data) {
    const index = state.resources.findIndex((i) => i.id === data.id)
    if (index > -1) {
      state.resources[index] = { ...state.resources[index], ...data }
    } else {
      state.resources.push(data)
    }
  },
  PATCH_SINGLE_DATA_RESOURCE(state, { meter_uuid, new_data }) {
    const index = state.resources.findIndex((i) => i.meter_uuid === meter_uuid)
    if (index > -1) {
      const raw_data = []
      const data_CO2 = []
      const data_MWh = []
      for (const [, val] of Object.entries(new_data.data)) {
        raw_data.push(val.raw)
        data_CO2.push(val.CO2)
        data_MWh.push(val.MWh)
      }
      state.resources[index] = {
        ...state.resources[index],
        raw_data,
        data_CO2,
        data_MWh,
      }
      if (new_data.last_month_CO2) {
        state.resources[index].last_month_CO2 = new_data.last_month_CO2
      }
      if (new_data.last_month_MWh) {
        state.resources[index].last_month_MWh = new_data.last_month_MWh
      }
      if (new_data.tot_CO2) {
        state.resources[index].tot_CO2 = new_data.tot_CO2
      }
      if (new_data.tot_MWh) {
        state.resources[index].tot_MWh = new_data.tot_MWh
      }
    }
  },
  ADD_GROUP(state, payload) {
    state.groups.push(payload)
  },
  ADD_TAG(state, payload) {
    state.tags.push(payload)
  },
  ADD_TAG_TO_METER(state, { meter_uuid, tag }) {
    const clone = [...state.meters]
    const index = clone.findIndex((m) => m.meter_uuid === meter_uuid)
    if (index > -1) {
      if (!tag.is_group) {
        clone[index].tags.push(tag)
      }
    }

    Vue.set(state, 'meters', clone)
  },
  ADD_GROUP_TO_METER(state, { meter_uuid, tag }) {
    const clone = [...state.meters]
    const index = clone.findIndex((m) => m.meter_uuid === meter_uuid)
    if (index > -1) {
      if (tag.is_group) {
        clone[index].groups.push(tag)
      }
    }

    Vue.set(state, 'meters', clone)
  },
  REMOVE_GROUP_TO_METER(state, { meter_uuid, tag_uuid }) {
    const clone = [...state.meters]
    const index = clone.findIndex((m) => m.meter_uuid === meter_uuid)
    if (index > -1) {
      const group_index = clone[index].groups.findIndex(
        (g) => g.tag_uuid === tag_uuid
      )
      if (group_index > -1) {
        clone[index].groups.splice(group_index, 1)
      }
    }

    Vue.set(state, 'meters', clone)
  },
  REMOVE_TAG_TO_METER(state, { meter_uuid, tag_uuid }) {
    const clone = [...state.meters]
    const index = clone.findIndex((m) => m.meter_uuid === meter_uuid)
    if (index > -1) {
      const tag_index = clone[index].tags.findIndex(
        (t) => t.tag_uuid === tag_uuid
      )
      if (tag_index > -1) {
        clone[index].tags.splice(tag_index, 1)
      }
    }

    Vue.set(state, 'meters', clone)
  },
  EDIT_GROUP(state, payload) {
    const index = state.groups.findIndex((u) => u.tag_uuid === payload.tag_uuid)
    if (index > -1) {
      state.groups.splice(index, 1, payload)
      state.meters.forEach((m) => {
        const tag_index = m.groups.findIndex(
          (t) => t.tag_uuid === payload.tag_uuid
        )
        if (tag_index > -1) {
          m.groups.splice(tag_index, 1, payload)
        }
      })
    } else {
      state.groups.push(payload)
    }
  },
  DELETE_GROUP(state, payload) {
    const index = state.groups.findIndex((u) => u.tag_uuid === payload.tag_uuid)
    if (index > -1) {
      state.groups.splice(index, 1)
      state.meters.forEach((m) => {
        const tag_index = m.groups.findIndex(
          (t) => t.tag_uuid === payload.tag_uuid
        )
        if (tag_index > -1) {
          m.groups.splice(tag_index, 1)
        }
      })
    }
  },
  EDIT_TAG(state, payload) {
    const index = state.tags.findIndex((u) => u.tag_uuid === payload.tag_uuid)
    if (index > -1) {
      state.tags.splice(index, 1, payload)
      state.meters.forEach((m) => {
        const tag_index = m.tags.findIndex(
          (t) => t.tag_uuid === payload.tag_uuid
        )
        if (tag_index > -1) {
          m.tags.splice(tag_index, 1, payload)
        }
      })
    } else {
      state.tags.push(payload)
    }
  },
  DELETE_TAG(state, payload) {
    const index = state.tags.findIndex((u) => u.tag_uuid === payload.tag_uuid)
    if (index > -1) {
      state.tags.splice(index, 1)
      state.meters.forEach((m) => {
        const tag_index = m.tags.findIndex(
          (t) => t.tag_uuid === payload.tag_uuid
        )
        if (tag_index > -1) {
          m.tags.splice(tag_index, 1)
        }
      })
    }
  },

  //ANALYTCIS
  GET_GRAPH_DATA(state, data) {
    state.analytics.dataset_list = data.dataset_list
    state.analytics.period = data.period
    state.analytics.frequency = data.frequency
  },

  FETCH_ANALYTICS_DATA(state, data) {
    state.analytics.dataset_list = data.dataset_list
    state.analytics.period = data.period
    state.analytics.frequency = data.frequency
    state.analytics.x_labels = data.x_labels
    state.analytics.data_sets = data.data_sets
    state.analytics.format = data.format
    state.analytics.rendered_frequency = data.rendered_frequency
    state.analytics.unit = data.unit
  },

  //LOGIN
  LOG_IN(state, { data, redirect }) {
    localStorageService.setAccessToken(data.token)
    localStorageService.setCsrfToken(data.csrfToken)

    state.current_user = data
    if (redirect) {
      router
        .push({
          name: 'Home',
        })
        .catch(() => {})
    }
  },
  LOG_OUT(state) {
    state.current_user = null
    localStorageService.clearAccessToken()
    localStorageService.clearCsrfToken()

    router
      .push({
        name: 'LoginView',
      })
      .catch(() => {})
  },
  //similar to log_in, but without redirect
  CHANGE_PASSWORD(state, { data }) {
    localStorageService.setAccessToken(data.token)
    localStorageService.setCsrfToken(data.csrfToken)

    state.current_user = data
  },

  //USER
  FETCH_USERS(state, payload) {
    state.users = payload
  },
  ADD_USER(state, payload) {
    state.users.push(payload)
  },
  EDIT_USER(state, payload) {
    const index = state.users.findIndex((u) => u.email === payload.email)
    if (index > -1) {
      state.users.splice(index, 1, payload)
    } else {
      state.users.push(payload)
    }
  },
  DELETE_USER(state, payload) {
    const index = state.users.findIndex((u) => u.email === payload.email)
    if (index > -1) {
      state.users.splice(index, 1)
    }
  },

  //METER
  FETCH_METERS(state, payload) {
    state.meters = payload
  },
  FETCH_METER(state, payload) {
    const index = state.meters.findIndex(
      (m) => m.meter_uuid === payload.meter_uuid
    )
    if (index > -1) {
      state.meters.splice(index, 1, payload)
    } else {
      state.meters.push(payload)
    }
  },
  ADD_METER(state, payload) {
    state.meters.push(payload)
  },
  EDIT_METER(state, payload) {
    const index = state.meters.findIndex(
      (m) => m.meter_uuid === payload.meter_uuid
    )
    if (index > -1) {
      state.meters.splice(index, 1, payload)
    } else {
      state.meters.push(payload)
    }
  },

  //MESSAGES
  FETCH_MESSAGES(state, payload) {
    state.messages = payload
  },
  ADD_MESSAGE(state, payload) {
    state.messages.push(payload)
  },
  EDIT_MESSAGE(state, { old_time_stamp, data }) {
    const index = state.messages.findIndex((m) => m.SK === old_time_stamp)

    if (index > -1) {
      state.messages.splice(index, 1, data)
    } else {
      state.messages.push(data)
    }
  },
  DELETE_MESSAGE(state, SK) {
    const index = state.messages.findIndex((m) => m.SK === SK)

    if (index > -1) {
      state.messages.splice(index, 1)
    }
  },
  FETCH_UNREAD(state, no_unread) {
    state.no_unread = no_unread
  },
  UPDATE_UNREAD(state, amount) {
    state.no_unread = amount
  },

  //ARCHIVE
  FETCH_ARCHIVE(state, data) {
    state.archives = data
  },
}
