import axios from 'axios'
import jwt_decode from 'jwt-decode'
import i18n from '@/plugins/i18n.js'
import LocalStorageService from '@/utils/LocalStorage.js'
import axiosSetup from '@/utils/API_Interceptors.js'

import {
  pieChartData,
  emissionLineChartOption,
  emissionLineChartPlugins,
  emissionLineChartData,
  pieChartData_c02,
  pieChartOptions,
  pieChartPlugins,
  barChartPlugins,
  barChartData,
  barChartData_c02,
  barChartOptions,
  resource_data,
  single_resource_data,
  fetch_graph_data,
  fetch_archive_data,
  fetch_info_data,
} from '@/store/mockData.js'

const localStorageService = LocalStorageService.getService()
const axios_instance = axios.create()
axiosSetup(axios_instance)
export default {
  //UI
  setCurrentView({ commit }, view) {
    commit('SET_CURRENT_VIEW', view)
  },

  //DASHBOARD
  async fetch_dashboard_data({ commit, getters }) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .get(`/api/dashboard/${org_uuid}`)
      .then(async (res) => {
        if (res.data) {
          commit('FETCH_DASHBOARD_DATA', res.data)
          //MOCK FOR LIVE DATA
          // const live_data = await dispatch('fetch_live_emission', {
          //   line_duration: 1,
          // })

          return {
            success: true,
            message: i18n.t('apiRelated.placeholder.action_success', {
              action: i18n.t('apiRelated.action.fetched'),
              key: i18n.t('apiRelated.item.data'),
            }),
            payload: { ...res.data },
          }
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.fetch').toLowerCase(),
            key: i18n.t('apiRelated.item.data'),
          }),
        }
      })
  },
  async fetch_dashboard_data_mock({ commit }, { line_duration }) {
    // return await axios.get('/api/dashboard').then().catch()
    //Use mock data
    const sum_procurement = []
    let y = 0

    while (y < 3) {
      const bg_color = y === 0 ? '#4FF7FF' : y === 1 ? '#ff7e00' : '#92CD00'
      sum_procurement.push({
        header: {
          title:
            y === 0
              ? 'ELECTRICITY (2022)'
              : y === 1
              ? 'HEATING (2022)'
              : 'TRANSPORT (2022)',
          action_title: true,
          action_identifier:
            y === 0 ? 'electricity' : y === 1 ? 'heating' : 'transport',
          subTitle:
            y === 0
              ? ['3456.45 MWh', '-5%']
              : y === 1
              ? ['5897.23 MWh', '13.15%']
              : ['67657.6 MWh', '0.88%'],
          legend: {
            data: [
              {
                label: '2020',
                styles: {
                  backgroundColor: ['#C0C0C0'],
                },
              },
              {
                label: '2021',
                styles: {
                  backgroundColor: [bg_color],
                },
              },
            ],
          },
        },
        l_chart: {
          chartData:
            y === 0
              ? pieChartData({ total: 500.55555, count: 3 })
              : y === 1
              ? pieChartData({ total: 600.3423, count: 3 })
              : pieChartData({ total: 700.3432, count: 3 }),
          chartOptions:
            y === 0
              ? pieChartOptions({ total: 500.55555 })
              : y === 1
              ? pieChartOptions({ total: 600.3423 })
              : pieChartOptions({ total: 700.3432 }),
          chartPlugins: pieChartPlugins(),

          type: 'pie',
          styles: {
            height: '20vh',
            minHeight: '10rem',
            width: '100%',
          },
          wrapperStyle: {
            display: 'flex',
            alignItems: 'flex-end',
          },
        },
        r_chart: {
          chartData: barChartData({ min: 5, max: 80, bg_color }),
          chartPlugins: barChartPlugins(),
          chartOptions: barChartOptions({}),
          type: 'bar',
          styles: {
            height: '20vh',
            width: '100%',
            minHeight: '10rem',
          },
          wrapperStyle: {
            display: 'flex',
            alignItems: 'flex-end',
          },
        },
      })

      y += 1
    }
    const c02_barchart_header = {
      title: 'HISTORY 2019-2021',
      legend: {
        data: [
          {
            label: '2019',
            styles: {
              backgroundColor: ['#C0C0C0'],
            },
            interactive: true,
            tooltip: i18n.t('common.label.total') + ': 500 [MWh]',
          },
          {
            label: '2020',
            styles: {
              backgroundColor: ['#808080'],
            },
            interactive: true,
            tooltip: i18n.t('common.label.total') + ': 500 [MWh]',
          },
          {
            label: '2021',
            styles: {
              backgroundColor: ['#10384B', '#800000', '#808000'],
            },
            interactive: true,
            tooltip: i18n.t('common.label.total') + ': 500 [MWh]',
          },
        ],
      },
    }
    const c02_barchart_total_header = {
      title: '',
      legend: {
        data: [
          {
            label: '2019',
            styles: {
              backgroundColor: ['#C0C0C0'],
            },
          },
          {
            label: '2020',
            styles: {
              backgroundColor: ['#4FF7FF'],
            },
          },
        ],
      },
    }
    const c02_piechart_year_header = {
      title: 'Monthly (2021)',
      action_title: true,
      action_identifier: 'ghg',
      subTitle: ['43543.45 (e)', '10.13%'],
      legend: {
        position: 'absolute',
        data: [
          {
            label: 'Electricity',
            styles: {
              backgroundColor: ['#4FF7FF'],
            },
          },
          {
            label: 'Heating',
            styles: {
              backgroundColor: ['#ff7e00'],
            },
          },
          {
            label: 'Transport',
            styles: {
              backgroundColor: ['#808000'],
            },
          },
        ],
      },
    }
    const c02_live_piechart_year_header = {
      title: '',
      // legend: {
      //   position: 'absolute',
      //   data: [
      //     {
      //       label: 'Electricity',
      //       styles: {
      //         backgroundColor: '#800000',
      //       },
      //     },
      //     {
      //       label: 'Heating',
      //       styles: {
      //         backgroundColor: '#10384B',
      //       },
      //     },
      //     {
      //       label: 'Fossil fuel',
      //       styles: {
      //         backgroundColor: '#008000',
      //       },
      //     },
      //     {
      //       label: 'Bio Fuel',
      //       styles: {
      //         backgroundColor: 'yellow',
      //       },
      //     },
      //     {
      //       label: 'Electricity import (FIN)',
      //       styles: {
      //         backgroundColor: '#C0C0C0',
      //       },
      //     },
      //     {
      //       label: 'Electricity import (SWE)',
      //       styles: {
      //         backgroundColor: '#808080',
      //       },
      //     },
      //   ],
      // },
    }
    const consumption_pie_header = {
      title: 'Monthly (2021)',
      subTitle: ['42343.45 (MWh)', '15.13%'],
      action_title: true,
      action_identifier: 'consumption',
      legend: {
        position: 'absolute',
        data: [
          {
            label: 'Electricity',
            styles: {
              backgroundColor: ['#4FF7FF'],
            },
          },
          {
            label: 'Heating',
            styles: {
              backgroundColor: ['#ff7e00'],
            },
          },
          {
            label: 'Transport',
            styles: {
              backgroundColor: ['#808000'],
            },
          },
        ],
      },
    }
    const emission_line_chart_header = {
      title: '',
      legend: {
        position: 'absolute',
        display: 'row',
        data: [
          {
            label: 'Fossil (ÅEA, ME, KNÅ)',
            index: 0,
            styles: {
              backgroundColor: '#800000',
            },
          },
          {
            label: 'Electricity import (FIN)',
            index: 1,
            styles: {
              backgroundColor: '#10384B',
            },
          },
          {
            label: 'Electricity import (SWE)',
            index: 2,
            styles: {
              backgroundColor: '#008000',
            },
          },
          {
            label: 'Solar PV (ME + ÅEA)',
            index: 3,
            styles: {
              backgroundColor: 'yellow',
            },
          },
          {
            label: 'Wind power',
            index: 4,
            styles: {
              backgroundColor: '#4FF7FF',
            },
          },
        ],
      },
    }

    const sum_consumption = {
      group_bar_chart: {
        chartData: barChartData_c02(),
        chartOptions: barChartOptions({ header: c02_barchart_header }),
        chartPlugins: barChartPlugins(),
        type: 'bar',
        styles: {
          height: '20vh',
          minHeight: '10rem',
          width: 'inherit',
        },
      },
      bar_chart: {
        chartData: barChartData({ min: 5, max: 80, bg_color: '#4FF7FF' }),
        chartPlugins: barChartPlugins(),
        chartOptions: barChartOptions({
          header: c02_barchart_total_header,
        }),
        type: 'bar',
        styles: {
          height: '20vh',
          minHeight: '10rem',
          width: '100%',
        },
        wrapperStyle: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      },
      pie_chart: {
        chartData: pieChartData_c02({ total: 12000.345444, count: 3 }),
        chartOptions: pieChartOptions({
          header: consumption_pie_header,
          total: 12000.345444,
        }),
        chartPlugins: pieChartPlugins(),
        type: 'pie',
        styles: {
          height: '20vh',
          minHeight: '10rem',
          width: '90%',
        },
      },
    }

    const live_line_emission = {
      chartData: emissionLineChartData({ duration: line_duration }),
      chartOptions: emissionLineChartOption({
        header: emission_line_chart_header,
      }),
      chartPlugins: emissionLineChartPlugins(),
      type: 'line',

      styles: {
        height: '90%',
        minHeight: '10rem',
        width: 'inherit',
      },
      // wrapperStyle: {
      //   marginTop: '2rem',
      // },
    }

    const backgroundColor = [
      '#800000',
      '#10384B',
      '#008000',
      'yellow',
      '#C0C0C0',
      '#808080',
      '#4FF7FF',
    ]
    const CO2_emissions = {
      group_bar_chart: {
        chartData: barChartData_c02(),
        chartOptions: barChartOptions({ header: c02_barchart_header }),
        chartPlugins: barChartPlugins(),
        type: 'bar',
        styles: {
          height: '20vh',
          minHeight: '10rem',
          width: 'inherit',
        },
      },
      bar_chart: {
        chartData: barChartData({ min: 5, max: 80, bg_color: '#4FF7FF' }),
        chartPlugins: barChartPlugins(),
        chartOptions: barChartOptions({
          header: c02_barchart_total_header,
        }),
        type: 'bar',
        styles: {
          height: '20vh',
          minHeight: '10rem',
          width: '100%',
        },
        wrapperStyle: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      },
      pie_chart: {
        chartData: pieChartData_c02({ total: 4545445.7755, count: 3 }),
        chartOptions: pieChartOptions({
          header: c02_piechart_year_header,
          total: 4545445.7755,
        }),
        chartPlugins: pieChartPlugins(),
        type: 'pie',
        styles: {
          height: '20vh',
          minHeight: '10rem',
          width: '90%',
        },
      },
    }

    const live_pie_plugins = [
      // {
      //   id: 'centerContent',
      //   beforeDraw(chart, args, options) {
      //     const {
      //       ctx,
      //       chartArea: { top, left, right, bottom, width, height },
      //     } = chart
      //     ctx.save()
      //     // ctx.font = options.fontSize + 'rem ' + options.fontFamily
      //     ctx.font = (height + width)/14 + 'px ' + options.fontFamily
      //     ctx.textAlign = 'center'
      //     ctx.fillStyle = options.fontColor
      //     ctx.fillText(
      //       'C02',
      //       width / 2 + (height + width)/14* 0.2,
      //       height / 2 + (height + width)/14 * 0.5
      //     )
      //   },
      // },
    ]

    let live_pie_chart_options = pieChartOptions({
      header: c02_live_piechart_year_header,
      total: 11330.876876,
      unit: '[t CO&#8322;-eq]'
    })

    live_pie_chart_options = {
      ...live_pie_chart_options,
      plugins: {
        ...live_pie_chart_options.plugins,
        centerContent: {
          fontColor: 'red',
          fontSize: 4,
          fontFamily: 'sans-serif',
          left: '20px',
        },
      },
    }

    const live_pie_emission = {
      chartData: pieChartData_c02({
        total: 11330.876876,
        count: 5,
        backgroundColor,
      }),
      chartOptions: live_pie_chart_options,

      chartPlugins: pieChartPlugins(live_pie_plugins),
      type: 'pie',
      styles: {
        height: '100%',
        minHeight: '10rem',
        width: '100%',
      },
    }

    commit('FETCH_DASHBOARD_DATA', {
      CO2_emissions,
      sum_consumption,
      sum_procurement,
      live_line_emission,
      live_pie_emission,
    })

    return {
      success: true,
    }
  },

  async fetch_live_emission({ commit, getters }, { line_duration }) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }
    const period = line_duration === 1 ? 24 : 168

    return await axios_instance
      // .get(`/api/dashboard/${org_uuid}/live_emission?period=${period}`)
      .get(`https://energyportal.fi/api/dashboard/superc970-c07c-11ec-aaa7-17686b83d0f1/live_emission?period=${period}`)
      .then(async (res) => {
        if (res.data) {
          commit('FETCH_LIVE_EMISSIONS_DATA', res.data)
          //MOCK FOR LIVE DATA
          // const live_data = await dispatch('fetch_live_emission', {
          //   line_duration: 1,
          // })

          return {
            success: true,
            message: i18n.t('apiRelated.placeholder.action_success', {
              action: i18n.t('apiRelated.action.fetched'),
              key: i18n.t('apiRelated.item.data'),
            }),
            payload: res.data,
          }
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.fetch').toLowerCase(),
            key: i18n.t('apiRelated.item.data'),
          }),
        }
      })
  },

  async fetch_live_emission_mock({ commit, getters }, { line_duration }) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }
    const period = line_duration === 1 ? 24 : 168
    const emission_line_chart_header = {
      title: '',
      legend: {
        position: 'absolute',
        display: 'row',
        data: [
          {
            label: 'Fossil (ÅEA, ME, KNÅ)',
            index: 0,
            styles: {
              backgroundColor: '#800000',
            },
          },
          {
            label: 'Electricity import (FIN)',
            index: 1,
            styles: {
              backgroundColor: '#10384B',
            },
          },
          {
            label: 'Electricity import (SWE)',
            index: 2,
            styles: {
              backgroundColor: '#008000',
            },
          },
          {
            label: 'Solar PV (ME + ÅEA)',
            index: 3,
            styles: {
              backgroundColor: 'yellow',
            },
          },
          {
            label: 'Wind power',
            index: 4,
            styles: {
              backgroundColor: '#4FF7FF',
            },
          },
        ],
      },
    }
    const c02_live_piechart_year_header = {
      title: '',
      // },
    }
    const live_line_emission = {
      chartData: emissionLineChartData({ duration: line_duration }),
      chartOptions: emissionLineChartOption({
        header: emission_line_chart_header,
      }),
      chartPlugins: emissionLineChartPlugins(),
      type: 'line',

      styles: {
        height: '90%',
        minHeight: '20vh',
        width: 'inherit',
      },
      // wrapperStyle: {
      //   marginTop: '2rem',
      // },
    }

    const backgroundColor = [
      '#800000',
      '#10384B',
      '#008000',
      'yellow',
      '#C0C0C0',
      '#808080',
      '#4FF7FF',
    ]

    const live_pie_plugins = []

    let live_pie_chart_options = pieChartOptions({
      header: c02_live_piechart_year_header,
      total: 11330.876876,
      unit: '[t CO&#8322;-eq]'
    })

    live_pie_chart_options = {
      ...live_pie_chart_options,
      plugins: {
        ...live_pie_chart_options.plugins,
        centerContent: {
          fontColor: 'red',
          fontSize: 4,
          fontFamily: 'sans-serif',
          left: '20px',
        },
      },
    }

    const live_pie_emission = {
      chartData: pieChartData_c02({
        total: 11330.876876,
        count: 7,
        backgroundColor,
      }),
      chartOptions: live_pie_chart_options,

      chartPlugins: pieChartPlugins(live_pie_plugins),
      type: 'pie',
      styles: {
        height: '100%',
        minHeight: '20vh',
        width: '100%',
      },
    }

    commit('FETCH_DASHBOARD_DATA', {
      live_line_emission,
      live_pie_emission,
    })

    return {
      success: true,
    }
  },

  //ADMIN
  async fetch_admin_data({ commit, getters }) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .get(`/api/org/${org_uuid}/org_admin_management_data`)
      .then((res) => {
        if (res.data) {
          const meter_list = []

          for (const meter of res.data.meters) {
            const meter_tags = []
            const meter_groups = []
            if (meter.tags) {
              meter.tags.forEach((item) => {
                if (item.is_group) {
                  meter_groups.push(item)
                } else {
                  meter_tags.push(item)
                }
              })
            }

            meter_list.push({
              ...meter,
              tags: meter_tags,
              groups: meter_groups,
            })
          }

          commit('FETCH_METERS', meter_list)
          if (res.data.all_tags) {
            const groups = []
            const tags = []
            res.data.all_tags.forEach((item) => {
              if (item.is_group) {
                groups.push(item)
              } else {
                tags.push(item)
              }
            })
            commit('FETCH_GROUPS', groups)
            commit('FETCH_TAGS', tags)
          }
        }
        return {
          success: true,
          message: i18n.t('apiRelated.placeholder.action_success', {
            action: i18n.t('apiRelated.action.fetched'),
            key: i18n.t('apiRelated.item.admin_data'),
          }),
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.fetch').toLowerCase(),
            key: i18n.t('apiRelated.item.admin_data'),
          }),
        }
      })
  },

  //TAG & GROUP
  async addTag({ commit, getters }, payload) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .post(`/api/org/${org_uuid}/tag`, payload)
      .then((res) => {
        if (res.data) {
          if (res.data.is_group) {
            commit('ADD_GROUP', {
              name: res.data.name,
              tag_uuid: res.data.tag_uuid,
              is_group: res.data.is_group,
              mts: res.data.mts,
            })
          } else {
            commit('ADD_TAG', {
              name: res.data.name,
              tag_uuid: res.data.tag_uuid,
              is_group: res.data.is_group,
              mts: res.data.mts,
            })
          }

          getters.meters.forEach((meter) => {
            if (res.data.mts.includes(meter.meter_uuid)) {
              if (
                !res.data.is_group &&
                !meter.tags.some((t) => t.tag_uuid === res.data.tag_uuid)
              ) {
                commit('ADD_TAG_TO_METER', {
                  meter_uuid: meter.meter_uuid,
                  tag: {
                    name: res.data.name,
                    tag_uuid: res.data.tag_uuid,
                    is_group: res.data.is_group,
                    mts: res.data.mts,
                  },
                })
              }

              if (
                res.data.is_group &&
                !meter.groups.some((t) => t.tag_uuid === res.data.tag_uuid)
              ) {
                commit('ADD_GROUP_TO_METER', {
                  meter_uuid: meter.meter_uuid,
                  tag: {
                    name: res.data.name,
                    tag_uuid: res.data.tag_uuid,
                    is_group: res.data.is_group,
                    mts: res.data.mts,
                  },
                })
              }
            }
          })
        }
        return {
          success: true,
          message: i18n.t('apiRelated.placeholder.action_success', {
            action: i18n.t('apiRelated.action.added'),
            key: payload.is_group
              ? i18n.t('apiRelated.item.group')
              : i18n.t('apiRelated.item.group'),
          }),
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.add').toLowerCase(),
            key: payload.is_group
              ? i18n.t('apiRelated.item.group')
              : i18n.t('apiRelated.item.group'),
          }),
        }
      })
  },
  async editTag({ commit, getters }, payload) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .patch(`/api/org/${org_uuid}/tag/${payload.tag_uuid}`, payload)
      .then((res) => {
        if (res.data) {
          if (res.data.is_group) {
            commit('EDIT_GROUP', {
              name: res.data.name,
              tag_uuid: res.data.tag_uuid,
              is_group: res.data.is_group,
              mts: res.data.mts,
            })
          } else {
            commit('EDIT_TAG', {
              name: res.data.name,
              tag_uuid: res.data.tag_uuid,
              is_group: res.data.is_group,
              mts: res.data.mts,
            })
          }

          getters.meters.forEach((meter) => {
            if (res.data.is_group) {
              if (
                !meter.groups.some((g) => g.tag_uuid === res.data.tag_uuid) &&
                res.data.mts.includes(meter.meter_uuid)
              ) {
                commit('ADD_GROUP_TO_METER', {
                  meter_uuid: meter.meter_uuid,
                  tag: {
                    name: res.data.name,
                    tag_uuid: res.data.tag_uuid,
                    is_group: res.data.is_group,
                    mts: res.data.mts,
                  },
                })
              }

              if (
                meter.groups.some((g) => g.tag_uuid === res.data.tag_uuid) &&
                !res.data.mts.includes(meter.meter_uuid)
              ) {
                commit('REMOVE_GROUP_TO_METER', {
                  meter_uuid: meter.meter_uuid,
                  tag_uuid: res.data.tag_uuid,
                })
              }
            } else {
              if (
                !meter.tags.some((g) => g.tag_uuid === res.data.tag_uuid) &&
                res.data.mts.includes(meter.meter_uuid)
              ) {
                commit('ADD_TAG_TO_METER', {
                  meter_uuid: meter.meter_uuid,
                  tag: {
                    name: res.data.name,
                    tag_uuid: res.data.tag_uuid,
                    is_group: res.data.is_group,
                    mts: res.data.mts,
                  },
                })
              }

              if (
                meter.tags.some((g) => g.tag_uuid === res.data.tag_uuid) &&
                !res.data.mts.includes(meter.meter_uuid)
              ) {
                commit('REMOVE_TAG_TO_METER', {
                  meter_uuid: meter.meter_uuid,
                  tag_uuid: res.data.tag_uuid,
                })
              }
            }
          })
        }
        return {
          success: true,
          message: i18n.t('apiRelated.placeholder.action_success', {
            action: i18n.t('apiRelated.action.edited'),
            key: payload.is_group
              ? i18n.t('apiRelated.item.group')
              : i18n.t('apiRelated.item.tag'),
          }),
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.edit').toLowerCase(),
            key: payload.is_group
              ? i18n.t('apiRelated.item.group')
              : i18n.t('apiRelated.item.tag'),
          }),
        }
      })
  },
  async delete_tag({ commit, getters }, payload) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .delete(`/api/org/${org_uuid}/tag/${payload.tag_uuid}`)
      .then((res) => {
        if (payload.is_group) {
          commit('DELETE_GROUP', payload)
        } else {
          commit('DELETE_TAG', payload)
        }
        return {
          success: true,
          message: i18n.t('apiRelated.placeholder.action_success', {
            action: i18n.t('apiRelated.action.deleted'),
            key: payload.is_group
              ? i18n.t('apiRelated.item.group')
              : i18n.t('apiRelated.item.tag'),
          }),
        }
      })

      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.delete').toLowerCase(),
            key: payload.is_group
              ? i18n.t('apiRelated.item.group')
              : i18n.t('apiRelated.item.tag'),
          }),
        }
      })
  },
  async fetch_resources({ commit }) {
    const resources = resource_data({ total: 10 })
    commit('GFETCH_RESOURCES', resources)

    return {
      success: true,
    }
  },
  async fetch_resource({ commit, getters }, { meter_uuid, start, end }) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .get(
        `/api/org/${org_uuid}/meter/${meter_uuid}/manual_input?start=${start}&end=${end}`
      )
      .then(async (res) => {
        if (res.data) {
          commit('FETCH_SINGLE_DATA_RESOURCE', res.data)

          return {
            success: true,
            message: i18n.t('apiRelated.placeholder.action_success', {
              action: i18n.t('apiRelated.action.fetched'),
              key: i18n.t('apiRelated.item.data'),
            }),
            payload: res.data,
          }
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.fetch').toLowerCase(),
            key: i18n.t('apiRelated.item.data'),
          }),
        }
      })
  },
  async update_data_resource({ commit, getters }, { meter_uuid, new_data }) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .put(`/api/org/${org_uuid}/meter/${meter_uuid}/manual_input`, new_data)
      .then(async (res) => {
        if (res.data) {
          commit('PATCH_SINGLE_DATA_RESOURCE', { meter_uuid, new_data })

          return {
            success: true,
            message: i18n.t('apiRelated.placeholder.action_success', {
              action: i18n.t('apiRelated.action.updated'),
              key: i18n.t('apiRelated.item.data'),
            }),
          }
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.update').toLowerCase(),
            key: i18n.t('apiRelated.item.data'),
          }),
        }
      })
  },

  //ANALYTICS
  get_graph_data({ commit }, payload) {
    const graph_data = fetch_graph_data(payload)
    commit('GET_GRAPH_DATA', graph_data)

    return {
      success: true,
      payload: graph_data,
    }
  },
  async fetch_graph_data({ commit, getters }, payload) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .post(`/api/analytics/${org_uuid}`, payload)
      .then(async (res) => {
        if (res.data) {
          const result = {
            dataset_list: res.data.data,
            period: payload.period,
            frequency: payload.frequency,
            x_labels: res.data.x_labels,
            format: payload.format,
            data_sets: payload.data_sets,
            rendered_frequency: payload.rendered_frequency,
            unit: payload.unit,
          }
          commit('FETCH_ANALYTICS_DATA', result)

          return {
            success: true,
            message: i18n.t('apiRelated.placeholder.action_success', {
              action: i18n.t('apiRelated.action.fetched'),
              key: i18n.t('apiRelated.item.data'),
            }),
            payload: result,
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          return {
            success: false,
            message: err.response.data.error,
          }
        }
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.fetch').toLowerCase(),
            key: i18n.t('apiRelated.item.data'),
          }),
        }
      })
  },
  async generate_graph_from_dashboard({ commit }, payload) {
    if (!payload) {
      return {
        success: false,
        message: i18n.t('apiRelated.placeholder.action_fail', {
          action: i18n.t('apiRelated.action.show').toLowerCase(),
          key: i18n.t('apiRelated.item.graph'),
        }),
      }
    }
    commit('FETCH_ANALYTICS_DATA', payload)
    return {
      success: true,
      message: i18n.t('apiRelated.placeholder.action_success', {
        action: i18n.t('apiRelated.action.showed'),
        key: i18n.t('apiRelated.item.graph'),
      }),
    }
  },

  //LOGIN
  async login({ commit }, payload) {
    const formData = new FormData()
    formData.append('email', payload.email)
    formData.append('password', payload.password)

    return await axios_instance
      .post('/api/user/login', formData)
      .then(async (res) => {
        if (res.data) {
          axios_instance.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${res.data.token}`

          axios_instance.defaults.headers.common['X-CSRF-TOKEN'] =
            res.data.csrfToken
          const decodedJwt = jwt_decode(res.data.token)

          const login_user = {
            name: decodedJwt.name,
            role: decodedJwt.role,
            org_uuid: decodedJwt.org_uuid,
            email: decodedJwt.email,
            phone: decodedJwt.phone,
            token: res.data.token,
            csrfToken: res.data.csrfToken,
          }

          commit('LOG_IN', { data: login_user, redirect: true })

          return {
            success: true,
            message: i18n.t('apiRelated.placeholder.action_success', {
              action: i18n.t('apiRelated.action.logged_in'),
            }),
            payload: {
              redirect: false,
            },
          }
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.log_in').toLowerCase(),
          }),
        }
      })
  },
  async logOut({ commit }) {
    const token = localStorageService.getAccessToken()
    if (token && axios_instance.defaults.headers.common['Authorization']) {
      await axios_instance.post('/api/user/logout')
      // .then(() => {
      //   return {
      //     success: true,
      //     message: i18n.t('apiRelated.placeholder.action_success', {
      //       action: i18n.t('apiRelated.action.logged_out'),
      //     }),
      //   }
      // })
      // .catch(() => {
      //   return {
      //     success: false,
      //     message: i18n.t('apiRelated.placeholder.action_fail', {
      //       action: i18n.t('apiRelated.action.log_in').toLowerCase(),
      //     }),
      //   }
      // })
    }

    commit('LOG_OUT')
    delete axios_instance.defaults.headers.common['Authorization']
    delete axios_instance.defaults.headers.common['X-CSRF-TOKEN']
  },

  async fetch_login_data({ commit }) {
    const access_token = localStorageService.getAccessToken()
    const csrf_token = localStorageService.getCsrfToken()

    if (access_token && csrf_token) {
      const decodedJwt = jwt_decode(access_token)

      if (decodedJwt.email) {
        axios_instance.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${access_token}`
        axios_instance.defaults.headers.common['X-CSRF-TOKEN'] = csrf_token
        return await axios_instance
          .get(`api/user/${decodedJwt.email}`)
          .then((res) => {
            const login_user = {
              name: res.data.name,
              role: res.data.role,
              org_uuid: res.data.org_uuid,
              email: res.data.email,
              phone: res.data.phone,
              token: access_token,
              csrfToken: csrf_token,
            }

            commit('LOG_IN', { data: login_user, redirect: false })
            return {
              success: true,
              message: i18n.t('apiRelated.placeholder.action_success', {
                action: i18n.t('apiRelated.action.fetched'),
                key: i18n.t('apiRelated.item.credential'),
              }),
            }
          })
          .catch(() => {
            return {
              success: false,
              message: i18n.t('apiRelated.placeholder.action_fail', {
                action: i18n.t('apiRelated.action.fetch').toLowerCase(),
                key: i18n.t('apiRelated.item.credential'),
              }),
            }
          })
      }
    }

    commit('LOG_OUT')
    return {
      success: true,
      message: i18n.t('apiRelated.placeholder.action_success', {
        action: i18n.t('apiRelated.action.fetched'),
        key: i18n.t('apiRelated.item.credential'),
      }),
    }
  },

  //USER
  async fetch_users({ commit, getters }) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .get(`/api/org/${org_uuid}/user`)
      .then((res) => {
        if (res.data) {
          commit('FETCH_USERS', res.data.user_list)
        }
        return {
          success: true,
          message: i18n.t('apiRelated.placeholder.action_success', {
            action: i18n.t('apiRelated.action.fetched'),
            key: i18n.t('apiRelated.item.users'),
          }),
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.fetch').toLowerCase(),
            key: i18n.t('apiRelated.item.users'),
          }),
        }
      })
  },
  async addUser({ commit, getters }, payload) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .post(`/api/org/${org_uuid}/user`, payload)
      .then((res) => {
        commit('ADD_USER', res.data)
        return {
          success: true,
          message: i18n.t('apiRelated.placeholder.action_success', {
            action: i18n.t('apiRelated.action.added'),
            key: i18n.t('apiRelated.item.user'),
          }),
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.add').toLowerCase(),
            key: i18n.t('apiRelated.item.user'),
          }),
        }
      })
  },
  async editUser({ commit, getters }, payload) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    if (!payload.email) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.user_missing_email'),
      }
    }

    return await axios_instance
      .patch(`/api/org/${org_uuid}/user/${payload.email}`, payload)
      .then((res) => {
        commit('EDIT_USER', res.data.user)
        //PATCH CURRENT USER IF ITS THE SAME PERSON
        if (getters.current_user.email === res.data.user.email) {
          axios_instance.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${res.data.token}`

          axios_instance.defaults.headers.common['X-CSRF-TOKEN'] =
            res.data.csrfToken
          commit('LOG_IN', {
            data: {
              ...getters.current_user,
              ...res.data.user,
              token: res.data.token,
              csrfToken: res.data.csrfToken,
            },
            redirect: false,
          })
        }
        return {
          success: true,
          message: i18n.t('apiRelated.placeholder.action_success', {
            action: i18n.t('apiRelated.action.edited'),
            key: i18n.t('apiRelated.item.user'),
          }),
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.edit').toLowerCase(),
            key: i18n.t('apiRelated.item.user'),
          }),
        }
      })
  },
  async delete_user({ commit, getters }, payload) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    if (!payload.email) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.user_missing_email'),
      }
    }

    if (!getters.is_admin) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.not_enough_authorization_level'),
      }
    }

    return await axios_instance
      .delete(`/api/org/${org_uuid}/user/${payload.email}`)
      .then((res) => {
        commit('DELETE_USER', payload)

        return {
          success: true,
          message: i18n.t('apiRelated.placeholder.action_success', {
            action: i18n.t('apiRelated.action.deleted'),
            key: i18n.t('apiRelated.item.user'),
          }),
        }
      })

      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.delete').toLowerCase(),
            key: i18n.t('apiRelated.item.user'),
          }),
        }
      })
  },
  async changePassword({ commit }, payload) {
    return await axios_instance
      .patch(`/api/user/password`, payload)
      .then((res) => {
        axios_instance.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${res.data.token}`

        axios_instance.defaults.headers.common['X-CSRF-TOKEN'] =
          res.data.csrfToken
        const decodedJwt = jwt_decode(res.data.token)

        const login_user = {
          name: decodedJwt.name,
          role: decodedJwt.role,
          org_uuid: decodedJwt.org_uuid,
          email: decodedJwt.email,
          phone: decodedJwt.phone,
          token: res.data.token,
          csrfToken: res.data.csrfToken,
        }

        commit('CHANGE_PASSWORD', { data: login_user })

        return {
          success: true,
          message: i18n.t('apiRelated.placeholder.action_success', {
            action: i18n.t('apiRelated.action.changed'),
            key: i18n.t('apiRelated.item.password'),
          }),
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.change').toLowerCase(),
            key: i18n.t('apiRelated.item.password'),
          }),
        }
      })
  },

  //METERS
  async fetch_meters({ commit, getters }) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .get(`/api/org/${org_uuid}/meter`)
      .then((res) => {
        if (res.data) {
          const meter_list = []

          for (const meter of res.data.meter_list) {
            const meter_tags = []
            const meter_groups = []
            if (meter.tags) {
              meter.tags.forEach((item) => {
                if (item.is_group) {
                  meter_groups.push(item)
                } else {
                  meter_tags.push(item)
                }
              })
            }

            meter_list.push({
              ...meter,
              tags: meter_tags,
              groups: meter_groups,
            })
          }
          commit('FETCH_METERS', meter_list)
        }
        return {
          success: true,
          message: i18n.t('apiRelated.placeholder.action_success', {
            action: i18n.t('apiRelated.action.fetched'),
            key: i18n.t('apiRelated.item.meters'),
          }),
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.fetch').toLowerCase(),
            key: i18n.t('apiRelated.item.meters'),
          }),
        }
      })
  },
  async addMeter({ commit, getters }, payload) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .post(`/api/org/${org_uuid}/meter`, payload)
      .then((res) => {
        if (res.data) {
          const meter_tags = []
          const meter_groups = []
          if (res.data.tags) {
            res.data.tags.forEach((item) => {
              if (item.is_group) {
                meter_groups.push(item)
              } else {
                meter_tags.push(item)
              }
            })
          }

          commit('ADD_METER', {
            ...res.data,
            tags: meter_tags,
            groups: meter_groups,
          })
        }

        return {
          success: true,
          message: i18n.t('apiRelated.placeholder.action_success', {
            action: i18n.t('apiRelated.action.added'),
            key: i18n.t('apiRelated.item.meter'),
          }),
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.add').toLowerCase(),
            key: i18n.t('apiRelated.item.meter'),
          }),
        }
      })
  },
  async editMeter({ commit, getters }, payload) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .patch(`/api/org/${org_uuid}/meter/${payload.meter_uuid}`, payload)
      .then((res) => {
        if (res.data) {
          const meter_tags = []
          const meter_groups = []
          if (res.data.tags) {
            res.data.tags.forEach((item) => {
              if (item.is_group) {
                meter_groups.push(item)
              } else {
                meter_tags.push(item)
              }
            })
          }

          commit('EDIT_METER', {
            ...res.data,
            tags: meter_tags,
            groups: meter_groups,
          })
        }

        return {
          success: true,
          message: i18n.t('apiRelated.placeholder.action_success', {
            action: i18n.t('apiRelated.action.edited'),
            key: i18n.t('apiRelated.item.meter'),
          }),
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.edit').toLowerCase(),
            key: i18n.t('apiRelated.item.meter'),
          }),
        }
      })
  },
  //DATA VIEW
  async fetch_data_view({ commit, getters }) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .get(`/api/org/${org_uuid}/org_data_view`)
      .then(async (res) => {
        if (res.data) {
          const meter_list = []

          for (const meter of res.data.meter_list) {
            const meter_tags = []
            const meter_groups = []
            if (meter.tags) {
              for (const [, item] of Object.entries(meter.tags)) {
                if (item.is_group) {
                  meter_groups.push(item)
                } else {
                  meter_tags.push(item)
                }
              }
            }

            meter_list.push({
              ...meter,
              tags: meter_tags,
              groups: meter_groups,
            })
          }
          commit('FETCH_RESOURCES', meter_list)

          return {
            success: true,
            message: i18n.t('apiRelated.placeholder.action_success', {
              action: i18n.t('apiRelated.action.fetched'),
              key: i18n.t('apiRelated.item.data'),
            }),
          }
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.fetch').toLowerCase(),
            key: i18n.t('apiRelated.item.data'),
          }),
        }
      })
  },
  //MESSAGES
  async fetch_messages({ commit, getters }) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .get(`/api/message/${org_uuid}/message`)
      .then(async (res) => {
        if (res.data) {
          commit('FETCH_MESSAGES', res.data.message_list)

          return {
            success: true,
            message: i18n.t('apiRelated.placeholder.action_success', {
              action: i18n.t('apiRelated.action.fetched'),
              key: i18n.t('apiRelated.item.messages'),
            }),
            payload: res.data.message_list,
          }
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.fetch').toLowerCase(),
            key: i18n.t('apiRelated.item.messages'),
          }),
        }
      })
  },
  async send_message({ commit, getters }, payload) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .post(`/api/message/${org_uuid}/message`, payload)
      .then(async (res) => {
        if (res.data) {
          commit('ADD_MESSAGE', res.data)

          return {
            success: true,
            message: i18n.t('apiRelated.placeholder.action_success', {
              action: i18n.t('apiRelated.action.added'),
              key: i18n.t('apiRelated.item.message'),
            }),
          }
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.add').toLowerCase(),
            key: i18n.t('apiRelated.item.message'),
          }),
        }
      })
  },
  async edit_message({ commit, getters }, payload) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .patch(
        `/api/message/${org_uuid}/message/${payload.DOC}/public/${
          payload.public ? 'True' : 'False'
        }`,
        payload
      )
      .then(async (res) => {
        if (res.data) {
          commit('EDIT_MESSAGE', {
            old_time_stamp: payload.DOC,
            data: res.data,
          })

          return {
            success: true,
            message: i18n.t('apiRelated.placeholder.action_success', {
              action: i18n.t('apiRelated.action.edited'),
              key: i18n.t('apiRelated.item.message'),
            }),
            payload: res.data,
          }
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.edit').toLowerCase(),
            key: i18n.t('apiRelated.item.message'),
          }),
        }
      })
  },
  async delete_message({ commit, getters }, payload) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .delete(
        `/api/message/${org_uuid}/message/${payload.DOC}/public/${
          payload.public ? 'True' : 'False'
        }`
      )
      .then(async (res) => {
        if (res.data) {
          commit('DELETE_MESSAGE', payload.SK)

          return {
            success: true,
            message: i18n.t('apiRelated.placeholder.action_success', {
              action: i18n.t('apiRelated.action.deleted'),
              key: i18n.t('apiRelated.item.message'),
            }),
          }
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.delete').toLowerCase(),
            key: i18n.t('apiRelated.item.message'),
          }),
        }
      })
  },
  async fetch_unread({ commit, getters }) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .get(`/api/message/${org_uuid}/unread`)
      .then(async (res) => {
        if (res.data) {
          commit('FETCH_UNREAD', res.data)

          return {
            success: true,
            message: i18n.t('apiRelated.placeholder.action_success', {
              action: i18n.t('apiRelated.action.fetched'),
              key: i18n.t('apiRelated.item.unread_messages'),
            }),
            payload: res.data,
          }
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.fetch').toLowerCase(),
            key: i18n.t('apiRelated.item.unread_messages'),
          }),
        }
      })
  },
  update_unread({ commit }, { amount }) {
    commit('UPDATE_UNREAD', amount)
  },

  //ARCHIVE
  async fetch_archive({ commit, getters }) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    const mock_archive = fetch_archive_data()
    commit('FETCH_ARCHIVE', mock_archive)
    return {
      success: true,
      message: i18n.t('apiRelated.placeholder.action_success', {
        action: i18n.t('apiRelated.action.fetched'),
        key: i18n.t('apiRelated.item.archives'),
      }),
      payload: mock_archive,
    }
  },

  //INFO
  // async fetch_info({ commit, getters }, { info_id }) {
  //   const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
  //   if (!org_uuid) {
  //     return {
  //       success: false,
  //       message: i18n.t('apiRelated.message.missing_org'),
  //     }
  //   }

  //   const mock_info = fetch_info_data(info_id)
  //   commit('FETCH_INFO', mock_info)
  //   return {
  //     success: true,
  //     message: i18n.t('apiRelated.placeholder.action_success', {
  //       action: i18n.t('apiRelated.action.fetched'),
  //       key: i18n.t('apiRelated.item.info'),
  //     }),
  //     payload: mock_info,
  //   }
  // },

  async get_excel_template({ getters }) {
    const org_uuid = getters.current_user ? getters.current_user.org_uuid : null
    if (!org_uuid) {
      return {
        success: false,
        message: i18n.t('apiRelated.message.missing_org'),
      }
    }

    return await axios_instance
      .get(`/api/org/${org_uuid}/excel_template`, {
        responseType: 'arraybuffer',
      })
      .then(async (res) => {
        if (res.data) {
          // let bstr = new Uint8Array(res.data).reduce((string, el) => string + String.fromCharCode(el), "")
          return {
            success: true,
            message: i18n.t('apiRelated.placeholder.action_success', {
              action: i18n.t('apiRelated.action.fetched'),
              key: i18n.t('apiRelated.item.unread_messages'),
            }),
            payload: res.data,
          }
        }
      })
      .catch(() => {
        return {
          success: false,
          message: i18n.t('apiRelated.placeholder.action_fail', {
            action: i18n.t('apiRelated.action.fetch').toLowerCase(),
            key: i18n.t('apiRelated.item.unread_messages'),
          }),
        }
      })
  },
}
