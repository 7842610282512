import store from '@/store'
import LocalStorageService from '@/utils/LocalStorage.js'
import jwt_decode from 'jwt-decode'
import { checkExpiryDate } from '@/utils/helper_funcs'
import moment from 'moment'

// LocalstorageService
const localStorageService = LocalStorageService.getService()

export default function axiosSetUp(axiosInstance) {
  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    function(config) {
      // Do something before request is sent
      const token = localStorageService.getAccessToken()
      if (token) {
        const decodedJwt = jwt_decode(token)
        let isExpired = checkExpiryDate(moment(decodedJwt.exp * 1000).format())
        
        if (!isExpired) {
          config.headers.Authorization = `Bearer ${token}`
        } else {
          store.commit('ACCESS_TOKEN_EXPIRED', true)
          store.commit('LOG_OUT')
          
        }
      }
      return config
    },
    function(error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function(error) {
      return Promise.reject(error)
    }
  )
}