import moment from 'moment'

const checkExpiryDate = (date) => {
  return moment(date).isBefore()
}

const convertDateFormat = (date, in_format, out_format) => {
  //convert from format to format
  if (!in_format) {
    return date.format(out_format)
  }
  return moment(date, in_format).format(out_format)
}

const convertDateToLocal = (date, in_format, out_format) => {
  //assuming input is utc
  if (!in_format) {
    moment.utc(date).local().format(out_format)
  }
  return moment.utc(date, in_format).local().format(out_format)
}

const convertDateToUtc = (date, in_format, out_format) => {
  if (!in_format) {
    //when date is already in DATE TYPE
    return moment.utc(date).format(out_format)
  }
  return moment.utc(moment(date, in_format)).format(out_format)
}

export {
  checkExpiryDate,
  convertDateFormat,
  convertDateToLocal,
  convertDateToUtc,
}
