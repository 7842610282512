import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
  },
  {
    path: '/analytics',
    name: 'AnalyticsView',

    component: () =>
      import(/* webpackChunkName: "AnalyticsView" */ '../views/AnalyticsView.vue'),
  },
  {
    path: '/data',
    name: 'DataView',

    component: () =>
      import(/* webpackChunkName: "DataView" */ '../views/DataView.vue'),
  },
  {
    path: '/login',
    name: 'LoginView',

    component: () =>
      import(/* webpackChunkName: "LoginView" */ '../views/LoginView.vue'),
  },
  {
    path: '/admin-management',
    name: 'AdminView',

    component: () =>
      import(/* webpackChunkName: "AdminView" */ '../views/AdminView.vue'),
  },
  {
    path: "/404",
    alias: "*",
    name: "NotFound",
    component: () =>
      import(
        /* webpackChunkName: "NotFoundView" */ "../views/NotFound.vue"
      ),
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
