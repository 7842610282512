import Vue from 'vue'
import Vuex from 'vuex'

import actions from '@/store/actions'
import getters from '@/store/getters'
import mutations from '@/store/mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    current_view: 'Home',
    access_token_expired: false,
    dashboard: {
      CO2_emissions: {},
      sum_consumption: {},
      sum_procurement: [],
      live_line_emission: null,
      live_pie_emission: null,
    },

    //Analytics
    analytics: {
      chart: {},
      dataset_list: [],
      period: null,
      frequency: null,
      x_labels: [],
      unit: null
    },

    //For Data view & Admin view
    resources: [],
    meters: [],
    groups: [],
    tags: [],

    //Credential
    current_user: {
      email: 'admin@email.com',
      password: '1234',
    },

    //For admin view
    users: [],

    //Messages
    messages: [],
    no_unread: 0,

    //Archives
    archives: []
  },
  mutations,
  actions,
  getters: getters,
})
