import Vue from 'vue'
import VueI18n from 'vue-i18n'

import fi from '@/plugins/lang/fi'
import en from '@/plugins/lang/en'
import sv from '@/plugins/lang/sv'

Vue.use(VueI18n)
const messages = {
  en,
  fi,
  sv
}

const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
})

export default i18n
