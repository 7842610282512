import ChartDataLabels from 'chartjs-plugin-datalabels'

import moment from 'moment'

//MOCKDATA FUNCTION
const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}
const getRandomSum = (max, thecount) => {
  let r = []
  let currsum = 0
  for (let i = 0; i < thecount - 1; i++) {
    r[i] = parseFloat(
      getRandomInt(1, max - (thecount - i - 1) - currsum).toFixed(3)
    )
    currsum += r[i]
  }
  r[thecount - 1] = parseFloat((max - currsum).toFixed(3))
  return r
}

//DASHBOARD VIEW
const pieChartData = ({ total, count }) => {
  return {
    labels: ['District heating', 'Oil', 'Own heating'],
    datasets: [
      {
        backgroundColor: ['#41B883', '#E46651', '#00D8FF'],
        data: getRandomSum(total, count),
      },
    ],
  }
}

const emissionLineChartOption = ({ header }) => {
  return {
    header,
    responsive: true,
    maintainAspectRatio: false,
    pointRadius: 0,
    plugins: {
      filler: true,
      title: {
        display: false,
      },
      tooltip: {
        mode: 'index',
      },
      legend: {
        display: false,
      },
    },

    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
      },
    },
  }
}

const emissionLineChartPlugins = () => {
  return []
}

const emissionLineChartData = ({ duration }) => {
  //1: 24 hours, 2: 1 week

  const getRandomList = (min, max) => {
    const randomList = []
    const counter = duration === 1 ? 96 : 168
    for (let i = 0; i < counter; i += 1) {
      randomList.push(getRandomInt(min, max))
    }
    return randomList
  }
  const getRandomList_neg = () => {
    const randomList = []
    const counter = duration === 1 ? 96 : 168
    for (let i = 0; i < counter; i += 1) {
      randomList.push(getRandomInt(-50, 10))
    }
    return randomList
  }
  let week_labels = []
  let day_labels = []

  if (duration === 1) {
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        day_labels.push(`${i}:${j === 0 ? `00` : 15 * j}`)
      }
    }
  } else {
    for (let i = 0; i < 7; i++) {
      for (let j = 0; j < 24; j++) {
        if (i === 0) {
          week_labels.push(`Monday ${j}:00`)
        }
        if (i === 1) {
          week_labels.push(`Tuesday ${j}:00`)
        }
        if (i === 2) {
          week_labels.push(`Wednesday ${j}:00`)
        }
        if (i === 3) {
          week_labels.push(`Thursday ${j}:00`)
        }
        if (i === 4) {
          week_labels.push(`Friday ${j}:00`)
        }
        if (i === 5) {
          week_labels.push(`Saturday ${j}:00`)
        }
        if (i === 6) {
          week_labels.push(`Sunday ${j}:00`)
        }
      }
    }
  }

  return {
    labels: duration === 1 ? day_labels : week_labels,
    datasets: [
      {
        label: 'Fossil (ÅEA, ME, KNÅ)',
        data: getRandomList(0, 0),
        borderColor: '#800000',
        backgroundColor: '#800000',
        fill: true,
      },
      {
        label: 'Electricity import (FIN)',
        data: getRandomList(0, 0),
        borderColor: '#10384B',
        backgroundColor: '#10384B',
        fill: true,
      },
      {
        label: 'Electricity import (SWE)',
        data: getRandomList_neg(),
        borderColor: '#008000',
        backgroundColor: '#008000',
        fill: true,
      },
      {
        label: 'Solar PV (ME + ÅEA)',
        data: getRandomList(0, 0),
        borderColor: 'yellow',
        backgroundColor: 'yellow',
        fill: true,
      },
      {
        label: 'Wind power',
        data: getRandomList(10, 50),
        borderColor: '#4FF7FF',
        backgroundColor: '#4FF7FF',
        fill: true,
        fCO2: 0.9,
      },
      // {
      //   label: 'Redddd',
      //   data: getRandomList(),
      //   borderColor: 'white',
      //   // backgroundColor: '#4FF7FF',
      //   // fill: true,
      //   type: 'line'
      // },
    ],
  }
}

const pieChartData_c02 = ({ total, count, backgroundColor }) => {
  return {
    labels: [
      'Fossil (ÅEA, ME, KNÅ)',
      'Electricity import (FIN)',
      'Electricity import (SWE)',
      'Solar PV (ME + ÅEA)',
      'Wind power',
    ],
    datasets: [
      {
        backgroundColor: backgroundColor
          ? backgroundColor
          : ['#4FF7FF', '#ff7e00', '#808000'],
        data: getRandomSum(total, count),
      },
    ],
  }
}

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('.pie-chart-tooltip')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.classList.add('pie-chart-tooltip')
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)'
    tooltipEl.style.borderRadius = '3px'
    tooltipEl.style.color = 'white'
    tooltipEl.style.opacity = 1
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, 0)'
    tooltipEl.style.transition = 'all .1s ease'

    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

const pieChartOptions = ({ title, header, total, unit }) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '50%',
    plugins: {
      tooltip: {
        enabled: false,

        external: (context) => {
          // Tooltip Element
          const { chart, tooltip } = context
          const tooltipEl = getOrCreateTooltip(chart)

          // Hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0
            return
          }

          // Set Text
          if (tooltip.body) {
            const bodyLines = tooltip.body.map((b) => b.lines)

            const labelContainer = document.createElement('div')
            bodyLines.forEach((body, i) => {
              const colors = tooltip.labelColors[i]

              const span = document.createElement('div')
              span.style.background = colors.backgroundColor
              span.style.borderColor = colors.borderColor
              span.style.borderWidth = '2px'
              span.style.marginRight = '5px'
              span.style.height = '10px'
              span.style.minHeight = '10px'
              span.style.width = '10px'
              span.style.minWidth = '10px'
              span.style.display = 'inline-block'

              const labelEntry = document.createElement('div')
              labelEntry.style.display = 'flex'
              labelEntry.style.alignItems = 'center'
              labelEntry.style.width = 'max-content'

              const label = document.createElement('div')
              label.style.fontSize = '12px'
              label.innerHTML = body
              labelEntry.appendChild(span)
              labelEntry.appendChild(label)

              labelContainer.appendChild(labelEntry)
            })

            while (tooltipEl.firstChild) {
              tooltipEl.removeChild(tooltipEl.lastChild)
            }
            tooltipEl.appendChild(labelContainer)
          }

          const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1
          tooltipEl.style.left = positionX + tooltip.caretX + 'px'
          tooltipEl.style.top = positionY + tooltip.caretY + 'px'
          tooltipEl.style.font = tooltip.options.bodyFont.string
          tooltipEl.style.padding =
            tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
        },
        callbacks: {
          label: (context) => {
            let label = context.label || ''

            if (label) {
              label += ': '
            }

            label += context.parsed

            if (total) {
              let val = Math.round((context.parsed * 100) / total)
              if (val > 100) {
                val = 100
              }
              if (unit) {
                label += ' ' + unit + ' (' + val + '%)'
              } else {
                label += ' (' + val + '%)'
              }
            }

            return label
          },
        },
      },
      title: {
        display: title ? true : false,
        text: title,
        align: 'start',
        font: {
          size: 16,
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        font: {
          weight: 'bold',
          size: '12',
        },
        anchor: 'middle',
        backgroundColor: function (context) {
          return context.dataset.backgroundColor
        },
        borderColor: 'white',
        borderRadius: 25,
        borderWidth: 2,
        padding: 6,
        color: 'white',
        display: function (context) {
          let dataset = context.dataset
          let count = dataset.data.length
          let value = dataset.data[context.dataIndex]
          return false
          return value > count * 1.5
        },
      },
    },

    layout: {
      padding: 15,
    },
    header,
  }
}

const pieChartPlugins = (plugins = []) => {
  return [ChartDataLabels, ...plugins]
}

const barChartPlugins = () => {
  return []
}

const barChartData = ({ min, max, bg_color }) => {
  const randomList = []
  for (let i = 0; i < 12; i += 1) {
    randomList.push(getRandomInt(min, max))
  }

  return {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    datasets: [
      {
        label: '2020',
        backgroundColor: '#C0C0C0',
        data: randomList,
      },
      {
        label: '2021',
        backgroundColor: bg_color ?? '#f87979',
        data: randomList,
      },
    ],
  }
}
const barChartData_c02 = () => {
  return {
    labels: ['Electricity', 'Heating', 'Transport'],
    datasets: [
      {
        label: '2019',
        backgroundColor: '#C0C0C0',
        data: [120, 200, 230, 555],
      },
      {
        label: '2020',
        backgroundColor: '#808080',
        data: [210, 100, 350, 855],
      },
      {
        label: '2021',
        backgroundColor: ['#4FF7FF', '#ff7e00', '#808000'],
        data: [520, 50, 430],
      },
    ],
  }
}

const barChartOptions = ({ title, header }) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: title ? true : false,
        text: title,
        font: {
          size: 16,
        },
        align: 'start',
      },
      subtitle: {
        display: true,
        text: 'sdfadsfds',
      },

      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        display: true,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    header,
  }
}

//DATA VIEW
const resource_data = ({ total }) => {
  const items = []
  const random_names = ['electricity', 'heating', 'bio fuel', 'transport']
  for (let i = 1; i < total; i += 1) {
    const randomList = []
    let total = 0
    for (let i = 0; i < 12; i += 1) {
      const val = getRandomInt(0, 250)
      total += val
      randomList.push(val)
    }
    items.push({
      id: i,
      name: random_names[getRandomInt(0, 3)] + ' - ' + i,
      type: i % 2 ? 1 : i % 3 ? 2 : 3, //1:auto, 2:manual, 3: virtual

      total_mwh: {
        this_year: getRandomInt(500, 10000),
        last_month: getRandomInt(100, 500),
      },
      total_co2: {
        this_year: getRandomInt(500, 10000),
        last_month: getRandomInt(100, 500),
      },
      factor: {
        mwh: getRandomInt(25, 150),
        c02: getRandomInt(25, 150),
      },

      group: i % 2 ? 'Electricity' : i % 3 ? 'Heating' : 'Fuel',
      tag: i % 2 ? 'Green energy' : i % 3 ? 'Solar energy' : 'Wind',
      data: {
        year: '2022',
        annual: total,
        month: randomList,
      },
    })
  }

  return items
}

const single_resource_data = ({ year }) => {
  const randomList = []
  let total = 0
  for (let i = 0; i < 12; i += 1) {
    const val = getRandomInt(0, 250)
    total += val
    randomList.push(val)
  }

  return {
    year: year,
    annual: total,
    month: randomList,
  }
}

const get_users = () => {
  const name_list = [
    'John Doe',
    'Mary Jane',
    'Lex Luther',
    'Peter Parker',
    'Jack Sparrow',
    'Donald Dump',
    'Donald Duck',
    'Vlad Putita',
  ]
  const user_list = []
  for (let i = 0; i < 8; i += 1) {
    user_list.push({
      id: i + 1,
      name: name_list[i],
      email: name_list[i].toLowerCase().replace(/ /g, '') + '@email.com',
      phone: '040123456',
    })
  }

  return user_list
}

//ARCHIVE
const fetch_archive_data = () => {
  return [
    {
      archive_id: '1',
      title: 'Dashboard explanation',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
      src: 'https://www.youtube.com/embed/NRdHsuuXxfk',
    },
    {
      archive_id: '2',
      title: 'Analytics explanation',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
      src: 'https://www.youtube.com/embed/QN1odfjtMoo',
    },
    {
      archive_id: '3',
      title: 'Data injection explanation',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
      src: 'https://www.youtube.com/embed/QDYDRA5JPLE',
    },
  ]
}

//ARCHIVE
const fetch_info_data = (info_id) => {
  const info_list = [
    {
      info_id: 'dashboard#live_electricity',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
    },
    {
      info_id: 'dashboard#energy_consumption',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
    },
    {
      info_id: 'dashboard#ghg_emissions',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
    },
    {
      info_id: 'dashboard#energy_procurement',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
    },
  ]

  return info_list.find((i) => i.info_id === info_id)
}

//ANALYTICS
const fetch_graph_data = (payload) => {
  const dataset = []
  let data_points = 0

  const start = moment(payload.datasets[0].start, 'DD-MM-YYYY')
  const end = moment(payload.datasets[0].end, 'DD-MM-YYYY')
  const x_labels = []
  let add_amount = 1
  let add_factor = null

  let x_label_format = 'MM'

  if (payload.frequency === 'minute-15') {
    data_points = Math.round(end.diff(start, 'minutes') / 15)
    add_amount = 15
    add_factor = 'm'
    x_label_format = 'DD-MM HH:mm'
  }

  if (payload.frequency === 'hour') {
    data_points = end.diff(start, 'hours')
    add_factor = 'h'
    x_label_format = 'DD-MM HH:mm'
  }

  if (payload.frequency === 'day') {
    data_points = end.diff(start, 'days')
    add_factor = 'd'
    x_label_format = 'DD-MM'
  }

  if (payload.frequency === 'month') {
    data_points = end.diff(start, 'months')
    add_factor = 'M'
    x_label_format = 'DD-MM'
  }

  if (payload.frequency === 'week') {
    data_points = end.diff(start, 'weeks')
    add_amount = 1
    add_factor = 'w'
    x_label_format = 'w, MM'
    x_labels.push('Week ' + start.format(x_label_format))
  } else {
    x_labels.push(start.format(x_label_format))
  }

  for (let i = 0; i < data_points; i += 1) {
    if (payload.frequency === 'week') {
      x_labels.push(
        'Week ' + start.add(add_amount, add_factor).format(x_label_format)
      )
    } else {
      x_labels.push(start.add(add_amount, add_factor).format(x_label_format))
    }
  }

  payload.datasets.forEach((entry) => {
    const randomList = []

    for (let i = 0; i < data_points + 1; i += 1) {
      const val = getRandomInt(0, 250)
      randomList.push(val)
    }

    dataset.push({
      ...entry,
      data: randomList,
      label: entry.name + '-' + start.format(payload.format),
    })
  })

  return {
    dataset_list: dataset,
    period: payload.period,
    frequency: payload.frequency,
    x_labels,
  }
}

export {
  getRandomInt,
  getRandomSum,
  pieChartData,
  emissionLineChartOption,
  emissionLineChartPlugins,
  emissionLineChartData,
  pieChartData_c02,
  pieChartOptions,
  pieChartPlugins,
  barChartPlugins,
  barChartData,
  barChartData_c02,
  barChartOptions,
  resource_data,
  single_resource_data,
  get_users,
  fetch_graph_data,
  fetch_archive_data,
  fetch_info_data,
}
