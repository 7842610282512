export default {
  //UI
  current_view(state) {
    return state.current_view
  },
  access_token_expired(state) {
    return state.access_token_expired
  },

  //DASHBOARD
  dashboard(state) {
    return state.dashboard
  },

  //ANALYTICS
  analytics(state) {
    return state.analytics
  },

  //METERS-RELATED
  meters(state) {
    return state.meters
  },
  resources(state) {
    return state.resources //MOCK
  },
  groups(state) {
    return state.groups
  },
  tags(state) {
    return state.tags
  },

  //USER MANAGEMENT
  users(state) {
    return state.users
  },

  //CREDENTIAL
  current_user(state) {
    return state.current_user
  },
  is_admin(state) {
    return state.current_user ? state.current_user.role === 'admin' : false
  },

  //MESSAGES
  messages(state) {
    return state.messages
  },
  no_unread(state) {
    return state.no_unread
  },

  //ARCHIVE
  archives(state) {
    return state.archives
  }
}
